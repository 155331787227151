import React, { useEffect, useState } from "react";
import { url } from "../../../Utilities/url";
import moment from "moment";
import { func } from "../../../Utilities/logFunc";
import { requestWalletUsageLimit } from "../../../Utilities/controller";
import { useNavigate } from "react-router";
import DownArrow from '../../../assets/images/downArrow-svg.svg'
import { toast } from "react-toastify";
import { errors } from "../../../Utilities/error";
import { useTranslation } from "react-i18next";
import {eventTracking} from "../../../firebaseAnalytics"; 
import { events } from "../../../Utilities/appEvents";
const ContestStructure = ({
  handleJoinContest,
  handlerWinning,
  tournaments,
  setShowRulesModal,
  setShowJoinModal,
  setShowJVC
}) => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {t} = useTranslation()

  const remainingPlayers =
    tournaments?.totalPlayers - tournaments?.totalJoinedPlayers;

  return (
    <div class="card tournametParticipationCard tournamentSoloCard">
      <div class="card-header">
        <h4 class="card-title mb-0 text-dark fw-bold">
          {func("length",tournaments?.title?.length)}
          {tournaments?.title?.length > 25 ? (
            <marquee>{tournaments?.title}</marquee>
          ) : (
            tournaments?.title
          )}
        </h4>
        <span  class="rulesLink"
         onClick={() => {
          navigator.clipboard.writeText(tournaments?.shortCode);
          toast.info(t('info_Copied'), {
            // position: "top-left",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }}
        >
           {t('tournaments.id')}: {tournaments?.shortCode}
        </span>
      </div>
      <div class="card-body" style={{}}>
        <div class="row">
          <div class="col-12">
            <div class="card-content">
              {
                 tournaments?.titles?.[1]?.value ? 
                 <div class="row justify-content-between py-2 contest_date_row" style={{backgroundColor:'var(--primary-color-dark)'}}>
                 <div class="col-3">
                   <label for="" class="d-block h6 text-white">
                   {t('tournaments.date')}
                   </label>
                   <h6 class="bodyBoldFont mb-0 text-white">
                     {moment(tournaments?.date).format("D/M/YYYY")}
                   </h6>
                 </div>
                 <div class="col-3 dividersX">
                   <label for="" class="d-block h6 text-white">
                   {t('tournaments.time')}
                   </label>
                   <h6 class="bodyBoldFont mb-0 text-white">
                     {moment(tournaments?.dateTime).format("hh:mm A")}
                   </h6>
                 </div>
                 <div class="col-3 dividersRight">
                   <label for="" class="d-block h6 text-ellipsis_contest text-white" >
                     {/* {tournaments?.titles?.[0]?.name?.length > 9
                       ? tournaments?.titles?.[0]?.name?.substring(0, 9 - 3) +
                         "..."
                       : tournaments?.titles?.[0]?.name} */}
                     {/* {tournaments?.titles[0]?.name} */}
                     {tournaments?.titles?.[0]?.name}
                   </label>
                   <h6 class="bodyBoldFont mb-0 text-ellipsis_contest text-white" >
                     {
                     // tournaments?.titles?.[0]?.value?.length > 9
                     //   ? tournaments?.titles?.[0]?.value?.substring(0, 9 - 3) +
                     //     "..."
                     //   : 
                       tournaments?.titles?.[0]?.value}
                     {/* {tournaments?.titles[0]?.value} */}
                   </h6>
                 </div>
                 <div class="col-3">
                   <label for="" class="d-block h6 text-ellipsis_contest text-white" >
                     {/* {tournaments?.titles?.[1]?.name?.length > 9
                       ? tournaments?.titles?.[1]?.name?.substring(0, 9 - 3) +
                         "..."
                       : tournaments?.titles?.[1]?.name} */}
                      { tournaments?.titles?.[1]?.name}
                     {/* {tournaments?.titles[1]?.name} */}
                   </label>
                   <h6 class="bodyBoldFont mb-0 text-ellipsis_contest text-white" >
                     {
                     // tournaments?.titles?.[1]?.value?.length > 9
                     //   ? tournaments?.titles?.[1]?.value?.substring(0, 9 - 3) +
                     //     "..."
                     //   :
                        tournaments?.titles?.[1]?.value}
                     {/* {tournaments?.titles[1]?.value} */}
                   </h6>
                 </div>
               </div>
               :
               <div class="row justify-content-between py-2 contest_date_row" style={{backgroundColor:'var(--primary-color-dark)'}}>
               <div class="col-4">
                 <label for="" class="d-block h6 text-white">
                 {t('tournaments.date')}
                 </label>
                 <h6 class="bodyBoldFont mb-0 text-white">
                   {moment(tournaments?.date).format("D/M/YYYY")}
                 </h6>
               </div>
               <div class="col-4 dividersX">
                 <label for="" class="d-block h6 text-white">
                 {t('tournaments.time')}
                 </label>
                 <h6 class="bodyBoldFont mb-0 text-white">
                   {moment(tournaments?.dateTime).format("hh:mm A")}
                 </h6>
               </div>
               <div class="col-4">
                 <label for="" class="d-block h6 text-ellipsis_contest text-white" >
                   {/* {tournaments?.titles?.[0]?.name?.length > 9
                     ? tournaments?.titles?.[0]?.name?.substring(0, 9 - 3) +
                       "..."
                     : tournaments?.titles?.[0]?.name} */}
                   {/* {tournaments?.titles[0]?.name} */}
                   {tournaments?.titles?.[0]?.name}
                 </label>
                 <h6 class="bodyBoldFont mb-0 text-ellipsis_contest text-white" >
                   {
                   // tournaments?.titles?.[0]?.value?.length > 9
                   //   ? tournaments?.titles?.[0]?.value?.substring(0, 9 - 3) +
                   //     "..."
                   //   : 
                     tournaments?.titles?.[0]?.value}
                   {/* {tournaments?.titles[0]?.value} */}
                 </h6>
               </div>
              
             </div>
              }
             
              <div class="row justify-content-between py-2 mt-1">
                <div class="col-4">
                  <label for="" class="d-block h6 text-dark">
                  {t('tournaments.prize_pool')}
                  </label>
                  <div class="d-flex align-items-center poolPrizeBox">
                    <span class="icon me-2">
                      <img
                        src={
                          url.imageUrl +
                          tournaments?.currency?.[0]?.outCurrency?.img?.default
                        }
                        alt=""
                      />
                    </span>
                    <h6 class="bodyBoldFont mb-0 text-dark">{tournaments?.prizePool}</h6>
                  </div>
                </div>
                {tournaments?.winningModel == "perKill" ? (
                  <div class="col-4 dividersX d-flex">
                    <div class="">
                      <label for="" class="d-block h6 text-dark">
                      {t('tournaments.per_kill')}
                      </label>
                      <div class="d-flex align-items-center poolPrizeBox">
                        <span class="icon me-2">
                          {tournaments?.currency[0]?.outCurrency?.code ==
                          "INR" ? (
                            "₹"
                          ) : (
                            <img
                              src={
                                url.imageUrl +
                                tournaments?.currency[0]?.outCurrency?.img
                                  ?.default
                              }
                              alt=""
                            />
                          )}
                        </span>
                        <h6 class="bodyBoldFont mb-0 text-dark">
                          {" "}
                          {tournaments?.killPoints}
                        </h6>
                      </div>
                    </div>
                  </div>
                ) : (
                    <div className="col-4 dividersX" onClick={() => {
                      handlerWinning(tournaments) 
                      eventTracking(events.Contest_winners, {
                        TLB_GameName: localStorage.getItem('activeGame'),
                        TLB_GameID: localStorage.getItem("activeGameId"),
                        TLB_GameType: tournaments?.gameTypeAry?._id,
                        TLB_GameTypeName: tournaments?.gameTypeAry?.name,
                        TLB_ContestID: tournaments?._id
                      });
                    }}>
                    <label for="" className="d-block h6 text-dark">
                    {t('tournaments.winners')}
                    </label>
                    
                    <div className="d-flex align-items-center poolPrizeBox">
                  <h6 className="bodyBoldFont mb-0 text-dark">
                    {tournaments?.totalWinners}
                  </h6>
                    {/* <span className="icon me-2">
                      <img
                        src={
                          DownArrow
                        }
                        alt=""
                      />
                    </span> */}
                    <i className="fas fa-chevron-down mx-2 text-dark"></i>
                   
                  </div>
                  </div>
                )}
                <div class="col-4 d-flex">
                  <div class="">
                    <label for="" class="d-block h6 text-dark">
                    {t('contest.join_using')}
                    </label>
                    <div class="d-flex align-items-center useCoinBox">
                      {
                        tournaments?.entryFee > 0 ? 
                        <span class="icon me-2">
                        <img
                          src={
                            url.imageUrl +
                            tournaments?.currency[0]?.inCurrency?.img?.default
                          }
                          alt=""
                        />
                      </span> :
                      ""
                      }
                     
                      <h6 class="bodyBoldFont mb-0 text-dark">{tournaments?.entryFee > 0 ? tournaments?.entryFee : t('tournaments.free') }</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <div
                    class="progress"
                    role="progressbar"
                    aria-label="Basic example"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      class="progress-bar bg-primary"
                      style={{
                        width: `${
                          (100 * tournaments?.totalJoinedPlayers) /
                          tournaments?.totalPlayers
                        }%`,
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between mt-2">
                    <h5 className="text-dark">{t('tournaments.players_remaining',{players:remainingPlayers})}</h5>
                    <h5 className="text-dark">{t('tournaments.players_joined',{players:tournaments?.totalJoinedPlayers})} </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {tournaments?.totalPlayers - tournaments?.totalJoinedPlayers > 0 ?        
        <div class="card-footer d-grid" style={{ background: "transparent" }}>
        {tournaments?.isJoined ? (
          <button
            class="btn btn-primary"
            onClick={() => {
              if (setShowJVC) setShowJVC(false);
              if (setShowJoinModal) setShowJoinModal(false);
              //event
              navigate("/home/contest-details/" + tournaments?._id);
            }}
          >
            {t('tournaments.joined')}
          </button>
        ) :
          (
            <button
                class="btn btn-primary"
                onClick={() => {
                  eventTracking(events.contest_join_now, {
                    TLB_GameName: tournaments?.gameAry?.[0]?.name,
                    TLB_GameType: tournaments?.gameTypeAry?.name,
                    TLB_GameID: tournaments?.gameAry?.[0]?._id,
                    TLB_ContestID: tournaments?._id
                  });
                  handleJoinContest(tournaments)
                }}
            >
              {t('contest.join_now')}
            </button>
          )}
      </div>   
      :
      <div class="card-footer d-grid" style={{ background: "transparent" }}>
        { tournaments?.isJoined && remainingPlayers == 0 ? (
             <button
             class="btn btn-primary"
             onClick={() => {
               if (setShowJVC) setShowJVC(false);
               if (setShowJoinModal) setShowJoinModal(false);
               //event
               navigate("/home/contest-details/" + tournaments?._id);
             }}
           >
             {t('tournaments.joined')}
           </button>         
          ) : (
            <button
            class="btn btn-primary disabled"
          // onClick={() =>
          //   navigate("/home/contest-details/" + tournaments?._id)
          // }
          >
            {t('tournaments.full')}
          </button>
          )}
      </div>
      }
    </div>

    
  );
};

export default ContestStructure;
