import React, { useEffect, useState } from "react";
import Diamond from "../../../assets/images/diamond-icon.png";
import Fea from "../../../assets/images/featured-1.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import Reward from "../../../assets/images/rewards.png";

import MyReward from "./MyReward";
import RewardCategoryList from "./RewardCategoryList";
import { useTranslation } from "react-i18next";
import {eventTracking} from "../../../firebaseAnalytics"; 
import {events} from "../../../Utilities/appEvents"; 
const RewardStore = () => {
  let [activeTab, setActiveTab] = useState(1);
  const {t} = useTranslation()
  useEffect(()=>{
    EventTracking(); 
    eventTracking(events.Reward_Tab );
  },[]);
  const EventTracking=()=>{
    eventTracking(events.MENU.REWARD_STORE);
  }

  return (
    <div class="content">
      <div className="content-inner">

      <div class="row gy-4">
        <div class="col-12 mt-5 pt-lg-4 commonTab contestTab">
          <ul class="nav nav-tabs row" id="contestTab" role="tablist">
            <li class="nav-item col d-grid" role="presentation">
              <button
                  class={`nav-link ${activeTab === 1 ? "active" : ""} bodyBoldFont`}
                  id="contest-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#rewards-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="rewards-tab-pane"
                  aria-selected="true"
                  onClick={() => {
                    setActiveTab(1)
                    eventTracking(events.Reward_Tab );
                   }}
              >
                 {t('reward_store.rewards')}
              </button>
            </li>
            <li class="nav-item col d-grid" role="presentation">
              <button
                  class={`nav-link ${activeTab === 2 ? "active" : ""} bodyBoldFont`}
                  id="tournaments-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#myrewards-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="myrewards-tab-pane"
                  aria-selected="false"
                  onClick={() => {
                    setActiveTab(2)
                    eventTracking(events.My_Reward_Tab );
                   }}
              >
                {t('reward_store.my_rewards')}
              </button>
            </li>
          </ul>
          <div class="tab-content pt-2" id="contestTabContent">
            <div
              class={`tab-pane fade ${activeTab === 1 ? "show active" : ""} bodyBoldFont`}
              id="rewards-tab-pane"
              role="tabpanel"
              aria-labelledby="contest-tab"
              tabindex="0"
            >
              {
                activeTab == 1 &&  <RewardCategoryList
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
              }
             
            </div>
            <div
              class={`tab-pane fade ${activeTab === 2 ? "show active" : ""} bodyBoldFont`}
              id="myrewards-tab-pane"
              role="tabpanel"
              aria-labelledby="tournaments-tab"
              tabindex="0"
            >
               {
                activeTab ==  2 &&  <MyReward activeTab={activeTab} setActiveTab={setActiveTab} />
              
              }
              
            </div>
          </div>
        </div>
      </div>
      </div>

    </div>
  );
};

export default RewardStore;
