import React, { useEffect, useState } from "react";
import Diamond from "../../../assets/images/diamond-icon.png";
import Fea from "../../../assets/images/featured-1.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import Reward from "../../../assets/images/rewards.png";

import { myRewardList } from "../../../Utilities/controller";
import { func } from "../../../Utilities/logFunc";
import { url } from "../../../Utilities/url";
import moment from "moment";
import { toast } from "react-toastify";
import { errors } from "../../../Utilities/error";

import Loader from "../../Common/Loader";
import MyTransactionModal from "../CoinStore/MyTransactionModal";
import Pagination from "../../Common/Pagination";
import { useTranslation } from "react-i18next";
import { logout } from "../../../Utilities/logout";
import {eventTracking} from "../../../firebaseAnalytics"; 
import {events} from "../../../Utilities/appEvents"; 

const MyReward = ({ activeTab }) => {
  const { t } = useTranslation();

  const [rewardList, setRewardList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showMTM, setShowMTM] = useState(false);
  let [limit, setLimit] = useState(12);
  const [skipCount, setSkipCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const getRewardList = async (skip) => {
    setLoading(true);
    try {
      // let userData = localStorage.getItem("userData");
      // userData = JSON.parse(userData);

      let data = {
        skip: skip,
        limit: limit,
        sort: "asc",
        sortBy: "createdAt",
      };

      const res = await myRewardList(data);

      if (res?.data?.list) {
        setRewardList(res?.data?.list);
        setTotalPages(Math.ceil(res?.data?.count/limit))

        setLoading(false);
      }
      func(res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      setLoading(false);
      toast.error(error?.response?.data?.errors[0]?.msg);
      if ( error?.response?.data?.errors?.[0]?.code == 'auth.validation.signin.account_inactive'){
        
      logout()

      }
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSkipCount((prev) => (page - 1) * limit);
    getRewardList(Math.ceil(page - 1) * limit);
  };
  useEffect(() => {
    getRewardList(0);
    setCurrentPage(1)
  }, [activeTab]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
    <div class="row g-20" style={{ columnGap: "20px" }}>
      <div
        class="mb-3 d-flex flex-row align-items-center justify-content-between"
        // style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h2 class="mb-0 fw-bold fs-2 text-warning">
          {t("reward_store.recently_redeem")}
        </h2>
        <button className="d11-recenttran" 
              onClick={()=> setShowMTM(true)}
        
        >
          <img src={Reward} className="icon-30" />
          <span>{t("reward_store.my_recent_transactions")}</span>
        </button>
      </div>
      <div class="row g-3 mb-4">
        {rewardList.length > 0 ?
          rewardList.map((item, ind) => (
            <div class="col-xxl-3 col-md-4 col-6" key={ind}>
              <div class="card rewardCard">
                <div class="card-body  pt-5">
                  <div class="card-tag" style={{backgroundColor:`${item?.deliveryStatus === "delivered" ? "#00B14F" : item?.deliveryStatus === "FAILED" ? "#F92C2C": "#525252" }`}}>
                    {console.log(item?.deliveryStatus)}
                    {item?.deliveryStatus === "delivered"
                      ? t('reward_store.approved')
                      :item?.deliveryStatus === "FAILED" ?  t('reward_store.failed') : t('reward_store.pending')}
                  </div>
                  <div class="card-icon text-center mb-4">
                    <img
                      src={url?.imageUrl + item?.reward?.img?.default}
                      alt=""
                    />
                  </div>
                  <div class="d-flex align-items-start justify-content-between">
                    <div>
                      <h4 class="bodyBoldFont h5 mb-2 text-dark">
                        {item?.reward?.name.length > 15 ? (
                          <marquee>{item?.reward?.name}</marquee>
                        ) : (
                          item?.reward?.name
                        )}
                      </h4>
                      
                    </div>
                    <div class="d-flex align-items-center">
                      <img
                         class="me-1 icon-24"
                        src={url?.imageUrl + item?.currency?.img?.default}
                        // height={"20px"}
                        alt=""
                      />
                      <h4 class="m-0 bodyBoldFont h5 text-dark">
                        {" "}
                        {item?.reward?.coinAmount}
                      </h4>
                    </div>
                  </div>
                  <h4 class="bodyBoldFont h5 mb-0 text-dark">
                        <span>
                          {moment(item?.createdAt).format("DD MMM YYYY")}
                        </span>
                        <span class="ms-3">
                          {moment(item?.createdAt).format("hh:mm A")}
                        </span>
                      </h4>
                </div>
                <div class="card-footer d-flex align-items-center justify-content-between ">
                  {item?.deliveryStatus === "delivered" ? (
                    <>
                      <p class="mb-0 small">{item?.redeemVoucherCode}</p>
                      <span
                        class="btn btn-primary btn-xs"
                        onClick={() => {
                          navigator.clipboard?.writeText(
                            item?.redeemVoucherCode
                          );
                          eventTracking(events.copy_redeem, {
                            TLB_RewardsProductID: item?._id,
                            TLB_ProdcutName: item?.reward?.name,
                            TLB_ProductAmount: item?.reward?.rewardVoucherAmount,
                            TLB_Currency: item?.currency?.name,
                            TLB_Amount: item?.reward?.coinAmount
                          })
                          toast.info(t("info_Copied"), {
                            // position: "top-left",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                        }}
                      >
                        {t("reward_store.copy")}
                      </span>
                    </>
                  ) : item?.redeemVoucherCode?.length > 20 ? (
                    <marquee>{item?.redeemVoucherCode}</marquee>
                  ) : (
                    item?.redeemVoucherCode
                  )}
                </div>
              </div>
            </div>
          ))
          :
          <h2 className="text-center">{t("notFound.no_rewards_found")}</h2>
          }
      </div>
      {totalPages > 1 && (
             
             <Pagination currentPage = {currentPage} totalPages = {totalPages} onPageChange={handlePageChange} />
           )}
    </div>
    {showMTM && (
            <MyTransactionModal showMTM={showMTM} setShowMTM={setShowMTM} />
          )}
    </>
    
  );
};

export default MyReward;
