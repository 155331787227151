import React from 'react'
import BaseModalSubscription from './BaseModalSubscription'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";

const NonPremiumCRMModal = ({showModal,setShowModal}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const buttonHandler = ()=>{
    setShowModal(false)
    navigate('/home/get-premium')
  }
  const handlerClose = ()=>{
    setShowModal(false)
  
  }
  return (
   
    <BaseModalSubscription text={t('premium.get_company_premium')} showModal={showModal} setShowModal={setShowModal} buttonHandler={buttonHandler} handlerClose={handlerClose}>
        <h6 className='mt-3'>
        {t('premium.is_your_reuqest_urgent')}
        </h6>
        {/* <h6 className='mt-3 d11-premium-h6'>As a <span><Link to={"/home/get-premium"} style={{ color: "var(--primary-color-emphasis)" }}>D11 Premium</Link></span> </h6> */}
     
        <h6 className='d11-premium-h6 text-center mt-2'>{t('premium.premium_users_get_their_queries_resolved_one_of_our_dedicated_customer_care_response')}</h6>
      <h6 className='d11-premium-h6 text-center'>{t('premium.premium_users_also_get_access_to_a_host_additional_benefits')}</h6>

      
    </BaseModalSubscription>
  )
}

export default NonPremiumCRMModal