import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  getContestDetails,
  getContestUserList,
} from "../../../Utilities/controller";
import { func } from "../../../Utilities/logFunc";
import Username from "../../../assets/images/username-icon-svg.svg";

import Rank from "../../../assets/images/ranking-icon-svg.svg";
import Kills from "../../../assets/images/kills-icon-svg.svg";
import { url } from "../../../Utilities/url";
import Trophy from '../../../assets/images/trophy.png';
import Coin from '../../../assets/images/orangeCoin.png';
import { useProfileDataContext } from "../ProfileContext";
import { useTranslation } from "react-i18next";


const ContestPlayers = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  // var user_data = JSON.parse(localStorage.getItem("userData"));

  const [contestUserData, setContestUserData] = useState([]);
  const [pages, setPages] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [totalPage, setTotalPage] = useState(false);
  const [rules, setRules] = useState(null);
const [captainData, setCaptainData] = useState()
const { profileData, updateProfileData } = useProfileDataContext()
const [contestStatus, setContestStatus] = useState('')
  useEffect(() => {
    fetchContestUserData(pages);
    fetchContestDetails();
  }, []);

  const fetchContestDetails = async () => {
    setLoading(true);
    try {
      const res = await getContestDetails(id);
      if (res?.data?.success) {
        setRules(res?.data?.data?.rules);
        setContestStatus(res?.data?.data?.status)
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      func("fetchContestDetails error", error?.response);
      setLoading(false);
    }
  };

  const fetchContestUserData = async (page) => {
    setLoading(true);
    try {
      const payload = {
        pagination: {
          pageNo: page,
          recordPerPage: 10,
          sortBy: "rank",
          sortDirection: "asc",
        },
        search: {
          user: profileData?.item?.user_id,
        },
      };
      const res = await getContestUserList(id, payload);
      if (res?.data?.success) {
        setContestUserData(res?.data?.data);
        setCaptainData(res?.data?.captainData)
      setTotalPage(res?.data?.totalPages)

        setLoading(false);
      } else {
        setLoading(false);
      }
      // getLoadMore();
    } catch (error) {
      func("fetchContestUserData error", error?.response);
      setLoading(false);
    }
  };

  const getLoadMore = async () => {
    setLoader(true);
    setPages(pages+1)
    var payloadPlayer = {
      pagination: {
        pageNo: pages+1,
        recordPerPage: 10,
        sortBy: "rank",
        sortDirection: "asc",
      },
      search: {
        user: profileData?.item?.user_id,
      },
    };
    let playerResponse = await getContestUserList(id, payloadPlayer);
    // console.log("playerResponse?.data?.totalPages === pages", playerResponse?.data?.totalPages + "===" + page);
    // if (playerResponse?.data?.totalPages === page) setIsLastPage(true);
    // if (playerResponse && playerResponse?.data?.length < 10) {
    //   setIsLastPage(true)
    // }
    if (playerResponse && playerResponse?.data) {
      // if (pages === 0)
      setContestUserData([...contestUserData, ...playerResponse?.data?.data]);
    }
    setLoader(false);
    // setPages(page);
  };

  return (
    <div class="col-lg-7">
      <div class="title">
        <h3 class="bodyBoldFont mb-3 d11-refer-color d11-premium">{t("contest_details.players")}</h3>
      </div>
      <div class="card playersCard">
        <div class="card-header">
          <div class="row justify-content-between align-items-center">
            <div class="col-lg-4 col-4 d-flex">
              <div class="text-center ps-lg-5 ps-4">
                <span class="icon mb-2 d-block">
                  <img className="icon-30" src={Username} alt="" />
                </span>
                <h5 class="bodyNormalFont mb-0">{t("contest_details.username")}</h5>
              </div>
            </div>
            <div class="col-lg-8 col-8">
              <div class="d-flex justify-content-between">
                <div class="col">
                  <h5 class="bodyNormalFont mb-0 p-2-3" style={{ marginTop: "1.4rem" }}>In Name Game</h5>
                </div>
                <div class="col text-center dividersRight">
                  <span class="icon mb-2 d-block">
                    <img className="icon-30" src={Kills} alt="" />
                  </span>
                  <h5 class="bodyNormalFont mb-0">{t("contest_details.kills")}</h5>
                </div>
                <div class="col text-center">
                  <span class="icon mb-2 d-block">
                    <img className="icon-30" src={Rank} alt="" />
                  </span>
                  <h5 class="bodyNormalFont mb-0">{t("contest_details.rank")}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card playersDetailCard mt-1 mb-4">
        <div class="card-body">
          <ul class="list-unstyled mb-0">
            {func("players details 143", contestUserData)}
            <li class="row justify-content-between align-items-center">
                <div class="col-lg-4 col-4">
                  <div class="d-flex align-items-center ps-4">
                    <span class="icon me-2 d-block">
                      <img
                        class="icon-50"
                        src={url.imageUrl + captainData?.userData?.userlevel?.level?.featuredImage?.default}
                        alt=""
                      />
                    </span>
                    <div style={{display:'flex', justifyContent:'space-between',width:'100%', flexDirection:'column'}}>
                    <h5 class="bodyNormalFont mb-0 text-dark" style={{display:'block',width:'10rem',whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{captainData?.userData?.gamerjiName}</h5> {" "}
                    { (contestStatus == 'completed' && captainData?.totalMultiplierAmount > 0) && <h6 class="bodyNormalFont mb-0 text-dark" style={{display:'block',width:'10rem',whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", marginTop:'2px', fontSize:'1rem'}}>
                    <img className="icon-9 ms-1"  src={Trophy} /> {' '}
                    {t("tournaments.won")} <img className="icon-9 ms-1"  src={Coin} /> {' '}
                        {captainData?.totalMultiplierAmount}
                      </h6> }                  
                    </div>
                  </div>
                </div>
                <div class="col-lg-8 col-8">
                  <div class="d-flex justify-content-between">
                    <div class="col">
                      <h5 class="bodyNormalFont mb-0 text-dark">
                        {captainData?.inGameUserName}
                      </h5>
                    </div>
                    <div class="col text-center">
                      <h5 class="bodyNormalFont mb-0 text-dark">
                        {captainData?.kills == 0 ? "-" : captainData?.kills}
                      </h5>
                    </div>
                    <div class="col text-center">
                      <h5 class="bodyNormalFont mb-0 text-dark">
                        {captainData?.rank == 0 ? "-" : captainData?.rank}
                      </h5>
                    </div>
                  </div>
                </div>
              </li>
            {contestUserData?.map((item,ind) => (
              item?._id != captainData?._id && 
              <li class="row justify-content-between align-items-center" key={ind}>
                <div class="col-lg-4 col-4">
                  <div class="d-flex align-items-center ps-4">
                    <span class="icon me-2 d-block">
                      <img
                        class="icon-50"
                        src={url.imageUrl + item?.userData?.userlevel?.level?.featuredImage?.default}
                        alt=""
                      />
                    </span>
                    <div style={{display:'flex', justifyContent:'space-between',width:'100%', flexDirection:'column'}}>
                    <h5 class="bodyNormalFont mb-0 text-dark" style={{display:'block',width:'10rem',whiteSpace: "nowrap", overflow: "hidden",
  textOverflow: "ellipsis"}}>{item?.userData?.gamerjiName}</h5> {" "}
                    { (contestStatus == 'completed' && item?.totalMultiplierAmount > 0) && <h6 class="bodyNormalFont mb-0 text-dark" style={{display:'block',width:'10rem',whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", marginTop:'2px', fontSize:'1rem'}}>
                    <img className="icon-9 ms-1"  src={Trophy} /> {' '}
                    {t("tournaments.won")}  <img className="icon-9 ms-1"  src={Coin} /> {' '}
                        {item?.totalMultiplierAmount}
                      </h6> }
                    {/* {
                      item?.isPremium ? 
                      <img className="icon-premium ms-1" src={item?.planName == "D11_CHAMPION" ? Champion : Master} /> :""
                    } */}
                    </div>
                  </div>
                </div>
                <div class="col-lg-8 col-8">
                  <div class="d-flex justify-content-between">
                    <div class="col">
                      <h5 class="bodyNormalFont mb-0 text-dark">
                        {item?.inGameUserName}
                      </h5>
                    </div>
                    <div class="col text-center">
                      <h5 class="bodyNormalFont mb-0 text-dark">
                        {item?.kills == 0 ? "-" : item?.kills}
                      </h5>
                    </div>
                    <div class="col text-center">
                      <h5 class="bodyNormalFont mb-0 text-dark">
                        {item?.rank == 0 ? "-" : item?.rank}
                      </h5>
                    </div>
                  </div>
                </div>
              </li>
            ))}
            {pages < totalPage && (
              <div className="load_more text-center">
                <button
                  className="btn btn-primary"
                  onClick={() => getLoadMore(pages + 1)}
                >
                  {loader === true ? "Loading ..." : t('tournaments.load_more')}
                </button>
              </div>
            )}
          </ul>
        </div>
      </div>
      <div class="title">
        <h3 class="bodyBoldFont mb-3 d11-refer-color d11-premium">{t("contest_details.rules")}</h3>
      </div>
      <div class="card rulesCard">
        <div class="card-body">
          <div dangerouslySetInnerHTML={{ __html: rules }} />
        </div>
      </div>
    </div>
  );
};

export default ContestPlayers;
