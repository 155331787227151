import React, { useEffect, useState } from "react";
import ProfileBG from "../../assets/images/profile-bg.jpg";
import DailyLoginRewards from "./DailyLoginRewards";
import { func } from "../../Utilities/logFunc";
import {
  collegeList,
  getAccountProfile,
  getHeaderApi,
  getStatistics,
  requestUpdateProfile,
  favGameList,
  getGamePlayed,
  unSubscriptiontTangerine
} from "../../Utilities/controller";
import { url } from "../../Utilities/url";
import LevelsModal from "./LevelsModal";
import EditProfile from "./EditProfile";
import Loader from "../Common/Loader";
import Select from "react-select";
import ChartImg from "../../assets/images/charts.png";
import CollegeCard from "./CollegeCard";
import CollegeModal from "./CollegeModal";
import { toast } from "react-toastify";
import { routes } from "../../Utilities/routesFb";
import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { Tooltip } from "react-bootstrap";
import GameInsightsChart from "../Subscription/GameInsightsChart";
import GameInsights from "../Subscription/GameInsightsSubscriptionModal";
import {
  useProfileDataContext,
  useuserDataContext,
} from "../HomePage/ProfileContext";
import { RWebShare } from "react-web-share";
import { Link } from "react-router-dom";
import Champion from "../../assets/images/dGames-champion.png";
import Master from "../../assets/images/dGames-master.png";
import { logout } from "../../Utilities/logout";
import { useTranslation } from "react-i18next";


const UserProfile = () => {
  const colors = [
    "#FF5A00",
    "linear-gradient(181deg, #FF5A00 -6.96%, rgba(255, 90, 0, 0.00) 97.18%)",
    "#FF5A00",
  ];
  let premiumUser = true;
 
  const [userData, setUserData] = useState([]);
  const [levelProgressBar, setLevelProgressBar] = useState(0);
  const [showLevel, setShowLevel] = useState(false);
  const [showEditProfile, setEditProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCollegeDetails, sethowCollegeDetails] = useState(false);
  const [collegeListData, setCollegeList] = useState([]);
  const [collegeValue, setCollegeValue] = useState("");
  const [searchValue, setSearchValue] = useState();
  const [statisticsVal, setStatisticsVal] = useState([]);
  const [changeDailyRewrds, setDailyRewards] = useState(false);
  const [showInsights, setShowInsights] = useState(false);
  const [showNonSubscribeInsights, setShowNonSubscribeInsights] =
    useState(false);
  const [gameId, setgameId] = useState("");
  const [gameName, setGameName] = useState("");
  const { profileData, updateProfileData } = useProfileDataContext();
  const {t} = useTranslation()
  const handleClose = () => {
    setDailyRewards(true);
  };

  const fetchAccountProfile = async () => {
    try {
      let payload = {
        user:window.location.pathname.split('/')[window.location.pathname.split('/').length-1]
      }
      const data = await getAccountProfile(payload);
      setUserData(data?.data);
      func("profile data", data?.data);
      if (data) {
        let currentLevelPoints =
          data?.data?.item?.level?.level?.endPoint -
          (data?.data?.item.level?.level?.startPoint - 1);

        let userPointsAsPerLevel =
          data?.data?.item.level?.points -
          (data?.data?.item.level?.level?.startPoint - 1);

        let lpVal = (userPointsAsPerLevel * 100) / currentLevelPoints;
        // console.log("lpVal===>", lpVal)
        setLevelProgressBar(lpVal);
        getStatisticsList();
        // getCollegeList();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const getCollegeList = async () => {
    try {
      let payload = {
        sort: "desc",
        skip: collegeListData?.length,
        limit: 10,
        sortBy: "createdAt",
        q: searchValue,
      };
      const res = await collegeList(payload);
      setCollegeList([...collegeListData, ...res?.data?.list]);
      func("college", res?.data);
    } catch (error) {
      setLoading(false);
      func("error", error);
    }
  };
  const getStatisticsList = async () => {
    try {
      let data = {
        user_id:profileData?.item?._id
      }
      let gameList = await getGamePlayed(data);
      // console.log(gameList?.data?.data)
      let payload = {
        games: [...gameList?.data?.data],
        appType: "webapp",
        user: profileData?.item?.user_id
      };
      const res = await getStatistics(payload);
      func("statistics", res?.data);
      setStatisticsVal(res?.data?.list);
    } catch (error) {
      func("error", error?.response?.data?.errors?.[0]?.msg);
    }
  };
  const handlerSelect = (e) => {
    func("college value", e);
    setCollegeValue(e?._id);
  };
  const handlerSubmit = async (e) => {
    let payload = {
      college: collegeValue,
    };
    try {
      const res = await requestUpdateProfile(payload);
      if (res) {
        window.location.href = "/" + routes.home + "/" + routes.myProfile;
      }
    } catch (error) {
      if (error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
      logout()
      toast.error(error?.response?.data?.errors[0]?.msg);
      func("erro", error?.response?.data?.errors[0]?.msg);
    }
  };
  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : "#fff",
      backgroundColor: state.isSelected
        ? "var(--secondary-color-emphasis)!important"
        : "var(--secondary-color-emphasis)!important",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "var(--secondary-color-emphasis)",
      padding: "8px",
      border: "none",
      boxShadow: "none",
      borderRadius: "50px",
      display: "flex",
      alignItems: "center",
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      marginRight: "5px",
      padding: "6px",
    }),
    indicatorsSeparator: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      display: "none",
    }),
    indicatorContainer: (defaultStyles) => ({
      ...defaultStyles,
      padding: "0",
      backgroundColor: "red",
    }),

    input: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "30px",
    }),
  };
  const getSeachCollegeList = async (searchValue) => {
    try {
      let payload = {
        sort: "desc",
        skip: collegeListData?.length,
        limit: 10,
        sortBy: "createdAt",
        q: searchValue,
      };
      const res = await collegeList(payload);
      setCollegeList([...res?.data?.list]);
      func("college list", res?.data);
    } catch (error) {
      func("error", error);
    }
  };
  const handlerViewInsights = (gameId, gameName) => {
    // if (premiumUser && localStorage.getItem("planName") == "D11_GRANDMASTER") {
    //   func("gamre id 166", gameId);
    //   setgameId(gameId);
    //   setGameName(gameName);
    //   setShowNonSubscribeInsights(false);

    //   setShowInsights(true);
    // } else {
    //   setShowInsights(false);
    //   setShowNonSubscribeInsights(true);
    // }
       setgameId(gameId);
      setGameName(gameName);
      setShowNonSubscribeInsights(false);
      setShowInsights(true);
  };

  useEffect(() => {
    // setLoading(true)
    fetchAccountProfile()

    let currentLevelPoints =
      profileData?.item?.level?.level?.endPoint -
      (profileData?.item.level?.level?.startPoint - 1);

    let userPointsAsPerLevel =
      profileData?.item.level?.points -
      (profileData?.item.level?.level?.startPoint - 1);

    let lpVal = (userPointsAsPerLevel * 100) / currentLevelPoints;
    setLevelProgressBar(lpVal);
    getStatisticsList();
    // getCollegeList();
  }, []);

  useEffect(() => {
    setCollegeList([]);
    getSeachCollegeList(searchValue);
  }, [searchValue]);

  if (loading) return <Loader />;
  return (
    <>
    <div class="content">
      <div className="content-inner">
        <div class="row gy-4">
          <div class="col-12">
            <div class="profileBannerCard card">
              <div class="card-img position-relative">
                <img src={ProfileBG} class="rounded10" alt="profile-bg" />
              </div>
              <div class="card-body">
                <div class="card-content d-flex justify-content-center">
                  <div class="profilecard p-1">
                    <div class="row px-2">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12 d-flex flex-column justify-content-center">
                            <div className="row">
                              <div className="col-10">
                              <h4 class="profiletitle">
                              {userData?.item?.gamerjiName}{" "}
                              {/* <img
                                className="icon-50  mx-2"
                                src={
                                  premiumUser
                                        ? localStorage.getItem("planName") ==
                                      "D11_CHAMPION"
                                          ? Champion
                                          : Master
                                    : ""
                                }
                              /> */}
                            </h4>
                              </div>
                              <div className="col-2">
                              <div class="row mt-4">                         
                          <RWebShare
                            data={{
                              text: t("Profile.join_text", {
                                name: process.env.REACT_APP_COMPANY_NAME,
                              }),
                              url: `${url.client_base_url}home/user-profile/${userData?.item?._id}`,

                              title: t("Profile.join_me", { name: process.env.REACT_APP_COMPANY_NAME }),
                            }}
                            onClick={() =>
                              console.info(
                                "share successful!",
                                `${url.client_base_url}home/user-profile/${userData?.item?._id}`
                              )
                            }
                          >
                            <div class="col-md-2 col-3 text-center">
                              <Link class="nav-link d-inline-block">
                                <span class="nav-link-icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewbox="0 0 20 20"
                                    fill="none"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M16.6668 4.79191C16.6668 5.32595 16.5089 5.84807 16.213 6.29262C15.917 6.73717 15.4963 7.08431 15.0036 7.29039C14.5109 7.49648 13.9683 7.5523 13.444 7.45086C12.9197 7.34941 12.4371 7.09522 12.0568 6.72024L7.75849 9.08857C7.9701 9.67913 7.9698 10.3249 7.75765 10.9152L12.0543 13.2819C12.5135 12.8287 13.1194 12.5542 13.7629 12.508C14.4064 12.4617 15.0453 12.6467 15.5645 13.0297C16.0838 13.4127 16.4492 13.9684 16.5951 14.5969C16.741 15.2253 16.6578 15.8852 16.3604 16.4578C16.063 17.0303 15.571 17.4779 14.9729 17.7199C14.3748 17.9619 13.71 17.9825 13.0981 17.7779C12.4863 17.5733 11.9675 17.157 11.6353 16.6039C11.3031 16.0509 11.1792 15.3974 11.286 14.7611L6.72099 12.2469C6.31335 12.5214 5.83871 12.6798 5.34793 12.7051C4.85714 12.7304 4.36872 12.6217 3.93499 12.3907C3.50126 12.1596 3.13857 11.8149 2.88578 11.3935C2.63299 10.9721 2.49964 10.4898 2.5 9.99835C2.50037 9.50692 2.63444 9.02485 2.88785 8.60379C3.14126 8.18273 3.50446 7.83856 3.93854 7.60815C4.37261 7.37774 4.8612 7.26978 5.35194 7.29584C5.84268 7.3219 6.31709 7.48099 6.72432 7.75607L11.286 5.24107C11.2254 4.87222 11.2418 4.49482 11.3342 4.13262C11.4266 3.77043 11.593 3.43128 11.8229 3.13653C12.0527 2.84177 12.3411 2.59779 12.6699 2.41995C12.9987 2.2421 13.3607 2.13423 13.7332 2.10314C14.1057 2.07204 14.4806 2.11839 14.8343 2.23927C15.188 2.36015 15.5129 2.55293 15.7885 2.8055C16.064 3.05806 16.2843 3.36493 16.4355 3.7068C16.5867 4.04866 16.6654 4.41811 16.6668 4.79191Z"
                                      fill="white"
                                    ></path>
                                  </svg>
                                </span>
                              </Link>
                            </div>
                          </RWebShare>
                          {/* <div
                            class="col-md-2 col-3 text-center"
                            style={{ cursor: "pointer" }}
                          >
                            <span
                              class="nav-link d-inline-block"
                              onClick={() => setEditProfile(true)}
                            >
                              <span class="nav-link-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewbox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M3.32604 14.0439L2.23104 16.9639C2.189 17.0763 2.18018 17.1984 2.20565 17.3156C2.23113 17.4329 2.28981 17.5403 2.37469 17.6251C2.45958 17.7099 2.56708 17.7685 2.68435 17.7938C2.80162 17.8192 2.92371 17.8102 3.03604 17.7681L5.95521 16.6731C6.28933 16.5479 6.59279 16.3527 6.84521 16.1006L15.301 7.64474C15.301 7.64474 15.006 6.76058 14.1227 5.87641C13.2394 4.99308 12.3544 4.69808 12.3544 4.69808L3.89854 13.1539C3.64639 13.4063 3.45118 13.7098 3.32604 14.0439ZM13.5335 3.51891L14.686 2.36641C14.8927 2.15974 15.1685 2.02808 15.4569 2.07641C15.8627 2.14308 16.4835 2.34474 17.0685 2.93058C17.6544 3.51641 17.856 4.13641 17.9227 4.54224C17.971 4.83058 17.8394 5.10641 17.6327 5.31308L16.4794 6.46558C16.4794 6.46558 16.1852 5.58224 15.301 4.69891C14.4177 3.81391 13.5335 3.51891 13.5335 3.51891Z"
                                    fill="white"
                                  ></path>
                                </svg>
                              </span>
                            </span>
                          </div> */}
                        </div>
                              </div>
                            </div>
                            
                            <div class="row">
                              <div class="col-xs-4 col-3 d-flex justify-content-center flex-column align-items-center">
                                <span>
                                  <img
                                    className="icon-80"
                                    src={
                                      url.imageUrl +
                                      userData?.item?.level?.level
                                        ?.featuredImage?.default
                                    }
                                    alt="profile-icon"
                                  />
                                </span>
                                <p>{userData?.item?.level?.level?.name}</p>
                              </div>
                              <div class="col-xs-8 col-9">
                                <div class="">
                                  <div class="row align-content-center mb-3 justify-content-between g-2">
                                    <div class="col-auto">
                                      <label
                                        for=""
                                        class="d-block h5 text-center"
                                      >
                                        {userData?.item?.level?.level?.num}
                                      </label>
                                      <h6 class="text-center d11-profile-level text-white">
                                      {t("Profile.level")}
                                      </h6>
                                    </div>
                                    {/* <div class="col-auto">
                                      <label
                                        for=""
                                        class="text-center d-block h5"
                                      >
                                        {userData?.item?.followers}
                                      </label>
                                      <h6 class="text-center d11-profile-level">
                                        {t("Profile.followers")}
                                      </h6>
                                    </div> */}
                                    {/* <div class="col-auto">
                                      <label
                                        for=""
                                        class="text-center d-block h5"
                                      >
                                        {userData?.item?.followings}
                                      </label>
                                      <h6 class="text-center d11-profile-level">
                                        {t("Profile.following")}
                                      </h6>
                                    </div> */}
                                  </div>
                                  <div className="row">
                                    <div
                                      class="col-10"
                                      style={{ position: "relative" }}
                                    >
                                      <span className="text-center d-block fs-10">
                                        {/* {userData?.item?.level?.points} PTS */}
                                        {t("Profile.n_pts", {
                                          n: userData?.item?.level?.points,
                                        })}
                                      </span>
                                      <div
                                        class="progress"
                                        role="progressbar"
                                        aria-label="Basic example"
                                        aria-valuenow="25"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      >
                                        <div
                                          class="progress-bar bg-primary"
                                          style={{
                                            width: `${levelProgressBar}%`,
                                          }}
                                        ></div>
                                      </div>
                                      <div class="d-flex justify-content-between mt-2">
                                        <p className="fs-10 mb-0">
                                          {/* {
                                            userData?.item?.level?.level
                                              ?.startPoint
                                          }{" "} */}
                                          {t("Profile.n_pts", {
                                            n: userData?.item?.level?.level
                                              ?.startPoint,
                                          })}
                                        </p>
                                        <p className="mb-0 fs-10">
                                          {/* {
                                            userData?.item?.level?.level
                                              ?.endPoint
                                          }{" "} */}
                                          {t("Profile.n_pts", {
                                            n: userData?.item?.level?.level
                                              ?.endPoint,
                                          })}
                                        </p>
                                      </div>
                                      <div class="d-flex justify-content-center">
                                        <span
                                          class="bodyBoldFont d11-insights-color cursorPointer"
                                          onClick={() => setShowLevel(true)}
                                        >
                                          {t("Profile.view_all_levels")}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-5 pt-lg-4 commonTab tournamentTypeTab">
          <div class="row gx-3 gy-5">
            <div class="col-12">
              <div class="pe-lg-3">
                <div class="col-12 commonTab profileTypeTab">
                  {/* <ul
                    class="nav nav-tabs row"
                    id="profileTypeTab"
                    role="tablist"
                  >
                    <li class="nav-item col d-grid" role="presentation">
                      <button
                        class="nav-link active"
                        id="statistics-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#statistics-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="statistics-tab-pane"
                        aria-selected="true"
                      >
                        {t("Profile.statistic")}
                      </button>
                    </li>
                    {/* <li class="nav-item col d-grid" role="presentation">
                      <button
                        class="nav-link"
                        id="collegiate-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#collegiate-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="collegiate-tab-pane"
                        aria-selected="false"
                      >
                        {t('Profile.collegiate')}
                      </button>
                    </li> */}
                  {/* </ul> */}
                  <h1 className="text-warning">{t("Profile.statistic")}</h1> 
                  
                  <div class="tab-content" id="earncoinTypeTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="statistics-tab-pane"
                      role="tabpanel"
                      aria-labelledby="statistics-tab"
                      tabindex="0"
                    >
                      <div class="row gx-3 gy-5">
                        {statisticsVal?.length > 0 ? (
                          statisticsVal?.map((ele, ind) => (
                            <div className="col-12">
                              <div
                                class="card statisticsCard text-center"
                                key={ind}
                              >
                                <div class="card-header py-3 d-flex align-items-center flex-row justify-content-between">
                                  <h6 class="card-text mb-0 ">
                                    {ele?.game?.name}:{" "}
                                    <span class="text-purple">
                                      {userData?.item?.gamerjiName}
                                    </span>
                                  </h6>
                                  <h6 class="card-text mb-0 ">
                                    {t("Profile.rank")}:{" "}
                                    <span class="text-purple">
                                      {ele?.avgRates}
                                    </span>
                                  </h6>
                                  <span
                                    class="bodyBoldFont d11-insights-color"
                                    onClick={() =>{
                                      // console.log(ele?.game?.name)
                                      handlerViewInsights(
                                        ele?.game?._id,
                                        ele?.game?.name
                                      )
                                    }
                                    }
                                  >
                                    {t("Profile.view_insights")}
                                  </span>
                                </div>

                                {/* <img src={ChartImg} alt="charts" /> */}
                                <ResponsiveContainer width="100%" aspect={3}>
                                  <AreaChart
                                    width={730}
                                    height={250}
                                    data={ele.stateatistic}
                                    margin={{
                                      top: 10,
                                      right: 30,
                                      left: 0,
                                      bottom: 0,
                                    }}
                                  >
                                    <defs>
                                    <linearGradient id="paint0_linear_15_122186" x1="789.867" y1="-10.0402" x2="789.598" y2="122.999" gradientUnits="userSpaceOnUse">
<stop stop-color="#FF5A00"/>
<stop offset="1" stop-color="#FF5A00" stop-opacity="0"/>
</linearGradient>
                                    </defs>
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Tooltip />
                                    <Area
                                      type="monotone"
                                      dataKey="played"
                                      stroke={colors[0]}
                                      fillOpacity={1}
                                      fill="url(#paint0_linear_15_122186)"
                                    />
                                  </AreaChart>
                                </ResponsiveContainer>
                              </div>
                            </div>
                          ))
                        ) : (
                          <h4>{t("notFound.no_records_found")}</h4>
                        )}
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="collegiate-tab-pane"
                      role="tabpanel"
                      aria-labelledby="collegiate-tab"
                      tabindex="0"
                    >
                      <div class="row gx-3">
                        <div class="row justify-content-center">
                          <div class="col-12">
                            {userData?.item?.collegeDetails ? (
                              <div class="card collegiateCard">
                                <div
                                  class="card-body"
                                  style={{ paddingTop: "3rem" }}
                                >
                                  <CollegeCard
                                    collegeDetails={
                                      userData?.item?.collegeDetails
                                    }
                                    sethowCollegeDetails={
                                      sethowCollegeDetails
                                    }
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="col-6 md-12 col-sm-6">
                                <Select
                                  styles={customStyles}
                                  name="countryOption"
                                  // value={countryCode}
                                  onMenuScrollToBottom={(e) => {
                                    getCollegeList();
                                  }}
                                  onInputChange={(e) => {
                                    setSearchValue(e);
                                    getCollegeList();
                                  }}
                                  options={collegeListData}
                                  onChange={(e) => handlerSelect(e)}
                                  getOptionLabel={(ele) => (
                                    <div
                                      className="d11-menu"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {ele?.name}
                                    </div>
                                  )}
                                />
                                <div className="col-12 d-grid mt-5">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-arrow"
                                    onClick={(e) => handlerSubmit(e)}
                                  >
                                    {t("crm.submit")}
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="col-lg-5 border-start border-gray-10 border-opacity-10 dailyLoginReward">
              <DailyLoginRewards handleClose={handleClose} />
              {
              ( isUserSubscribed || liteProfile?.isUserSubscribed) && 
                  <div style={{textAlign:'center'}}>
                    <button class="btn btn-sm btn-third text-white mx-2 my-1" style={{textTransform:'uppercase'}} onClick = {()=> { unSubscribe()}} >
                      {buttonLoader ? `${t('tournaments.loading')}...` : t('Profile.unsubscribe')}
                    </button>
                  </div>
              }
            </div> */}
            </div>
        </div>
      </div>
    </div>
    {showLevel && (
      <LevelsModal showLevel={showLevel} setShowLevel={setShowLevel} />
    )}
    {showEditProfile && (
      <EditProfile
        showEditProfile={showEditProfile}
        setEditProfile={setEditProfile}
        userData={userData}
      />
    )}
    {showCollegeDetails && (
      <CollegeModal
        collegeDetails={userData?.item?.collegeDetails}
        showCollegeDetails={showCollegeDetails}
        sethowCollegeDetails={sethowCollegeDetails}
      />
    )}
    {showInsights && (
      <GameInsightsChart
        showInsights={showInsights}
        setShowInsights={setShowInsights}
        gameId={gameId}
        gamename={gameName}
      />
    )}
    {showNonSubscribeInsights && (
      <GameInsights
        showModal={showNonSubscribeInsights}
        setShowModal={setShowNonSubscribeInsights}
      />
    )}
  </>
  );
};

export default UserProfile;
