import React, { useEffect, useState } from 'react'
import { useTheme } from '../../ThemeContext'


import LandingNavbar from './LandingNavbar'
import './landingPage.css'
import './queries.css';
import CardSection from './CardSection';
import LandingMain from './LandingMain';
import Contact from './Contact';
import { Login } from '@mui/icons-material';
import Footer from './Footer';
import { useSearchParams, useNavigate, useLocation, redirect } from "react-router-dom";

const LandingPage = ({children,active}) => {
    const globalvalues = useTheme()
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(()=>{
      if(searchParams.get("correlatorId")) {
        // const params = {};
        // console.log('----------------', location)
        navigate('/home' + location.search)
      }
    },[])
  return (
    <div className='landing-authModule'>
       {/* <div class="main-bg"> */}
     <LandingNavbar active={active}  />
{children}
    <Footer />
     {/* </div> */}
    </div>
  )
}

export default LandingPage