import React from "react";
import { url } from "../../../Utilities/url";
import moment from "moment";
import { func } from "../../../Utilities/logFunc";
import { json, useNavigate } from "react-router";
import DownArrow from "../../../assets/images/downArrow-svg.svg";
import { toast } from "react-toastify";
import { errors } from "../../../Utilities/error";
import { useTranslation } from "react-i18next";
const TournamentStructure = ({
  handlerJoin,
  handlerWinning,
  tournaments,
  setShowRulesModal,
  setSelectedTournament,
  setShowJoinModal,
  setShowTournamentModal,
  setShowJVC,
  isTournamentStarted
}) => {
  const remainingPlayers =
    tournaments?.totalPlayers - tournaments?.totalJoinedPlayers;

  const findPercentage = (data) => {
    let totalPlayer = data?.totalPlayers || 0;
    let totalPlayerJoined = data?.totalJoinedPlayers || 0;

    let percentage = (totalPlayerJoined / totalPlayer) * 100;

    return percentage + "%";
  };

  const navigate = useNavigate();
  const {t} = useTranslation()

  const handlerJoinTournament = () => {
    setShowJoinModal(false);
    if(setShowJVC) setShowJVC(false)
    navigate("/home/tournament-details/" + tournaments?._id);
  };

  return (
    <div className="card tournametParticipationCard">
      <div className="card-header" >
        <h4 className="card-title mb-0 text-dark">{tournaments?.title?.length > 20 ? <marquee>{tournaments?.title}</marquee> : tournaments?.title}</h4>
        <span
          className="rulesLink"
          onClick={() => {
            func("rules");
            setShowRulesModal(true);
            setSelectedTournament(tournaments);
          }}
        >
           {t('tournaments.view_rules')}
        </span>
      </div>
      <div className="card-body">
        <div className="row justify-content-md-start justify-content-center">
          <div className="col-md-3 col-sm-5 col-6 pe-md-0" 
          // style={{paddingRight:'0'}}
          >
            <div
              className="card-img"
              // style={{ width: "100%", height: "240px" }}
            >
              {tournaments?.featuredImage?.default ? (
                <img
                  // style={{ width: "100%" }}
                  src={url.imageUrl + tournaments?.featuredImage?.default}
                  alt=""
                  className="w-100 rounded-1 height-240"
                />
              ) : (
                <img
                  src={url.imageUrl + tournaments?.featuredImage?.default}
                  alt=""
                  className="w-100 rounded-1 height-240"
                />
              )}
              <div className="img-title text-uppercase bodyHeavyFont w-100 text-center h4 mb-0">
                {tournaments?.gameTypeAry?.name}
              </div>
            </div>
          </div>
          <div className="col-md-9 col-12 mt-md-0 mt-5">
            <div className="card-content">
              <div className="row justify-content-between py-2 ps-3" >
                <div className="col-4">
                  <label for="" className="d-block h5 text-dark">
                  {t('tournaments.date')}
                  </label>
                  <h5 className="bodyBoldFont mb-0 text-dark">
                    {/* {moment(tournaments?.contestsList?.[0]?.date).format(
                      "D/M/YYYY"
                    )} */}
                    {moment(tournaments?.startDate).format(
                      "D/M/YYYY"
                    )}
                  </h5>
                </div>
                <div className="col-4 dividersX">
                  <label for="" className="d-block h5 text-dark">
                  {t('tournaments.rounds')}
                  </label>
                  <h5 className="bodyBoldFont mb-0 text-dark">
                    {tournaments?.rounds?.length}
                  </h5>
                </div>
                <div className="col-4 text-dark">
                  <label for="" className="d-block h5 text-dark">
                  {t('tournaments.id')}
                  </label>
                  <h5
                    className="bodyBoldFont mb-0 text-dark"
                    onClick={() => {
                      navigator.clipboard.writeText(tournaments?.shortCode);
                      toast.info(t('info_Copied'), {
                        // position: "top-left",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    }}
                  >
                    {tournaments?.shortCode}
                  </h5>
                </div>
              </div>
              <div className="bg-primary py-2 mt-1">
                <div className="row ps-1">
                  <div
                    className={`col-6 ${tournaments?.titles?.[1]?.value && "dividersRight"} ps-4`}
                    // style={{ paddingLeft: "40px" }}
                  >
                    <label for="" className="d-block h5 text-ellipsis_contest" >
                      {
                      // tournaments?.titles?.[0]?.name?.length > 15
                      //   ? tournaments?.titles?.[0]?.name?.substring(0, 15 - 3) +
                      //     "..."
                      //   : 
                        tournaments?.titles?.[0]?.name
                        }
                      {/* {tournaments?.titles[0]?.name} */}
                    </label>
                    <h5 className="bodyBoldFont mb-0 text-ellipsis_contest text-white" >
                      {
                      // tournaments?.titles?.[0]?.value?.length > 15
                      //   ? tournaments?.titles?.[0]?.value?.substring(0, 15 - 3) +
                      //     "..."
                      //   : 
                        tournaments?.titles?.[0]?.value
                        }
                      {/* {tournaments?.titles[0]?.value} */}
                    </h5>
                  </div>
                  {
                    tournaments?.titles?.[1]?.value && 
                  <div className="col-6">
                    <label for="" className="d-block h5 text-ellipsis_contest" >
                      {/* {tournaments?.titles?.[1]?.name?.length > 15
                        ? tournaments?.titles[1]?.name?.substring(0, 15 - 3) +
                          "..."
                        : */}
                        { tournaments?.titles?.[1]?.name}
                        {/* // } */}
                      {/* {tournaments?.titles[1]?.name} */}
                    </label>
                    <h5 className="bodyBoldFont mb-0 text-ellipsis_contest text-white" >
                      {
                      // tournaments?.titles?.[1]?.value?.length > 15
                      //   ? tournaments?.titles?.[1]?.value?.substring(0, 15 - 3) +
                      //     "..."
                      //   : 
                        tournaments?.titles?.[1]?.value
                        }
                      {/* {tournaments?.titles[1]?.value} */}
                    </h5>
                  </div>
                  }
                  
                </div>
              </div>
              <div className="row justify-content-between py-2 ps-3 mt-1">
                <div className="col-4">
                  <label for="" className="d-block h5 text-dark">
                  {t('tournaments.prize_pool')}
                  </label>
                  <div className="d-flex align-items-center poolPrizeBox">
                    {
                      tournaments?.prizePoolInfo?.[0]?.totalAmount ?   
                      <span className="icon me-2 img-center">
                      <img
                        className="icon"
                        src={
                          url?.imageUrl +
                          tournaments?.currency?.[0]?.inCurrency?.img?.default
                        }
                        alt=""
                      />
                    </span>
                    :<></>

                    }
                   
                    <h5 className="bodyBoldFont mb-0 text-dark">
                    {tournaments?.prizePoolInfo?.[0]?.totalAmount ? tournaments?.prizePool : tournaments?.rewardDisplayText}
                    </h5>
                  </div>
                </div>
                <div className="col-4 dividersX" style={{ cursor: "pointer" }} onClick={handlerWinning}>
                  <label for="" className="d-block h5 text-dark">
                  {t('tournaments.winners')}
                  </label>

                  <div className="d-flex align-items-center poolPrizeBox">
                    <h5 className="bodyBoldFont mb-0 text-dark">
                      {tournaments?.totalWinners}
                    </h5>
                    {/* <span className="icon me-2">
                      <img src={DownArrow} alt="" />
                    </span> */}
                    <span className="icon me-2">
                      <i className="fas fa-chevron-down mx-2" style={{marginTop: '-4px'}}></i>
                    </span>
                  </div>
                </div>
                <div className="col-4">
                  <label for="" className="d-block h5 text-dark">
                  {t('tournaments.join_using')}
                  </label>
                  <div className="d-flex align-items-center poolPrizeBox">
                    {tournaments?.entryFee > 0 ? (
                      <span className="icon me-2 img-center">
                        <img
                          src={
                            url.imageUrl +
                            tournaments?.currency?.[0]?.inCurrency?.img?.default
                          }
                          alt=""
                        />
                      </span>
                    ) : (
                      ""
                    )}

                    <h5 className="bodyBoldFont mb-0 text-dark">
                      {tournaments?.entryFee > 0
                        ? tournaments?.entryFee
                        : t('tournaments.free')}
                    </h5>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-12">
                  <div
                    className="progress"
                    role="progressbar"
                    aria-label="Basic example"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      className="progress-bar bg-primary"
                      style={{ width: findPercentage(tournaments) }}
                    ></div>
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    <h5 className="text-dark">{t('tournaments.players_remaining',{players:remainingPlayers})}</h5>
                    <h5 className="text-dark">{t('tournaments.players_joined',{players:tournaments?.totalJoinedPlayers})}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      { tournaments?.totalPlayers - tournaments?.totalJoinedPlayers > 0 ?
      <div className="card-footer d-grid">
        {
        tournaments?.isJoined == false ?
        
        (
          isTournamentStarted ?  <button
          className="btn btn-primary"
          style={{opacity:'0.9'}}
          disabled
          // onClick={() => handlerJoin(tournaments)}
        >
          {/* {t('tournaments.join_now')} */}
          {t('tournaments.registration_closed')}
        </button>
        :
          <button
            className="btn btn-primary"
            onClick={() => handlerJoin(tournaments)}
          >
            {t('tournaments.join_now')}
          </button>
        )
         : (
          <button className="btn btn-primary" onClick={handlerJoinTournament}>
             {t('tournaments.joined')}
          </button>
        )}
      </div>
      :
    ((tournaments?.isJoined == true && remainingPlayers == 0) ?
      (
        <button className="btn btn-primary" onClick={handlerJoinTournament}>
           {t('tournaments.joined')}
        </button>
      )
      :(
        <div className="card-footer d-grid">
        <button
          class="btn btn-primary disabled"
          // onClick={() =>
          //   navigate("/home/contest-details/" + tournaments?._id)
          // }
        >
          {t('tournaments.full')}
        </button>
    </div>
      )
      )
      }
    </div>
  );
};

export default TournamentStructure;
