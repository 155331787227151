import React, { useEffect, useState } from 'react'
import Select from "react-select";
import { getLanguage } from '../../Utilities/controller';
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Loader from '../Common/Loader';
import { useNavigate } from 'react-router';
import { eventTracking } from "../../firebaseAnalytics";
import { events } from "../../Utilities/appEvents";
const Header = () => {
  const [languages, setLanguages] = useState()
  const [loader, setLoader] = useState()
  const [lang, setLang] = useState( localStorage.getItem("tlbLang")  || Cookies.get("tlbLangName") ||  "English")
  const currentLang = localStorage.getItem("tlbLangCode") || Cookies.get("tlbLangCode")  ||  "en"
  const {t} = useTranslation()
  const navigate = useNavigate()
  const [selectedLanguage, setSelectedLanguage] = useState(
    Cookies.get("tlbLangName") || localStorage.getItem("tlbLangName")  || "English"
 );
  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : "#fff",
      backgroundColor: state.isSelected
        ? "var(--secondary-color-emphasis)!important"
        : "purple",
      height: "34px",
      "&:hover": {
        color: "var(--accent-color)",
      },
      padding: "0px 0px",
      borderRadius: "20px",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "var(--secondary-color-emphasis)",
      padding: "8px",
      border: "none",
      boxShadow: "none",
      borderRadius: "50px",
      display: "flex",
      alignItems: "center",
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      width: "70px",
      marginRight: "5px",
      padding: "6px",
    }),
    indicatorsSeparator: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      display: "none",
    }),
    indicatorContainer: (defaultStyles) => ({
      ...defaultStyles,
      padding: "0",
      backgroundColor: "red",
    }),

    input: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "30px",
    }),
    dropdownIndicator: (defaultStyles) => ({
      ...defaultStyles,
      color:'var(--color-white)'
    })
  };

  const fetchLanguage = async()=>{
    const body={
      "companyId":process.env.REACT_APP_COMPANY_ID
  }
    try{
      const res = await getLanguage(body)
      // console.log(res)
      setLanguages(res?.data?.data)
    }catch(error){
      console.log(error)
    }
  }

  
  const onChangeLang = async(e) => {
    setLoader(true)
    setSelectedLanguage(e.name);
    const lang_code = e.code.toLowerCase();
    i18next.changeLanguage(lang_code);
    setLang(e.name)
    localStorage.setItem("tlbLangName", e.name);
                          localStorage.setItem(
                            "tlbLangCode",
                            e?.code?.toLowerCase()
                          );
    // localStorage.setItem('tlbLang',lang_code)
    // Cookies.set('tlbLang',e.name)
    Cookies.set('tlbLangCode',lang_code)
    Cookies.set('tlbLangName',e.name)

    window.location.href = window.location.pathname
    setLoader(false)
    eventTracking(events.CHANGE_LANGUAGE,{
      TLB_language: lang_code
    });
  };
  useEffect(()=>{
    fetchLanguage()
    // console.log(currentLang)
    const linkTag = document.getElementById('bootstrap_css');
    document.documentElement.dir =
      currentLang.toLowerCase() == "ar"  ? "rtl" : "ltr";
      console.log(currentLang)
    if (currentLang.toLowerCase() == "ar" ) {
      document.documentElement.setAttribute('lang', 'ar');
      linkTag?.setAttribute(
        'href',
        `${process.env.REACT_APP_CLIENT_BASE_URL}assets/css/bootstrap.rtl.min.css`
      );
    }
    document.title = t('title')
  },[currentLang, t])
  if(loader) <Loader />
  return (
    <>

    <header>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-4"></div>
        <div className="col-4 text-center" onClick={()=> navigate('/')}>
          <img className="navbar-brand" src="assets/images/logo.png" alt="" />
        </div>
          <div className="col-4 text-end">
          <div className="dropdown">
            <span
              className="dropdown-toggle language-dropdown"
             
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {selectedLanguage}
            </span>
            <ul className="dropdown-menu">
              {
                languages?.map((ele,ind)=>(
                  <li key={ind} onClick={()=> onChangeLang(ele)} ><span className="dropdown-item" >{ele?.name}</span></li>
                ))
              }
             
              
            </ul>
          </div>
           {/* <Select
                          styles={customStyles}
                          name="countryOption"
                          // value={countryCode}
                          defaultValue={languages?.[0]}
                          options={languages}
                          onChange={(e) => {
                            // localStorage.setItem("eng", e.value)
                            // console.log(e.value)
                            onChangeLang(e.code)
                            // window.location.reload()
                          }}
                          getOptionLabel={(ele) => (
                            // <div
                            //   className="d11-menu"
                            //   style={{ display: "flex", alignItems: "center" }}
                            // >
                             
                              ele?.name
                            // </div> 
                          )}
                          /> */}
        </div>
      </div>
    </div>
  </header>
  </>
  )
}

export default Header