import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { url } from "../../../Utilities/url";
import { accountCheckEmail } from "../../../Utilities/controller";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field } from "formik";

import * as Yup from "yup";
import { logout } from "../../../Utilities/logout";
import { errors } from "../../../Utilities/error";
import { useProfileDataContext } from "../ProfileContext";
import { useTranslation } from "react-i18next";

const EmailVerificationModal = ({
  emailVerificationModal,
  setEmailVerificationModal,
  sendRequest,
}) => {
  const [show, setShow] = useState(emailVerificationModal);
  const { fetchAccountProfile } = useProfileDataContext();
  const { t } = useTranslation();
  const [timerCount, setTimer] = useState(0); // Set initial timer to 0
  const [frmInitialValues, setFrmInitialValues] = useState({
    email: "",
    isSubmitEmail: false,
    otp: "",
    type: "otpRequest",
  });

  const handleClose = () => {
    setEmailVerificationModal(false);
    setShow(false);
    sendRequest(false);
  };

  const startCountDown = () => {
    setTimer(30); // Set timer to 30 seconds
  };

  const handleSubmit = async (values) => {
    try {
      if (frmInitialValues?.type === "otpRequest") {
        if (
          values?.email &&
          values?.email.trim()
        ) {
          let data = {
            type: "otpRequest",
            email: values?.email,
          };

          const res = await accountCheckEmail(data);

          if (res?.data?.success) {
            let fromdataobj = { ...frmInitialValues };
            fromdataobj.isSubmitEmail = true;
            fromdataobj.type = "validateOTP";
            setFrmInitialValues(fromdataobj);
            startCountDown(); // Start the timer on success
          } else {
            toast.error(res?.data?.errors?.[0]?.msg);
          }
        } else {
          toast.error(t('error_Please_enter_a_valid_email_address'));
        }
      } else {
        if (
          values?.otp &&
          values?.otp.trim()
        ) {
          let data = {
            type: "validateOTP",
            email: values?.email,
            otp: values?.otp,
          };

          const res = await accountCheckEmail(data);

          if (res?.data?.success) {
            sendRequest(true);
            setEmailVerificationModal(false);
            fetchAccountProfile();
            setShow(false);
          } else {
            toast.error(res?.data?.errors?.[0]?.msg);
          }
        } else {
          toast.error(t('error_enter_code'));
        }
      }
    } catch (error) {
      setEmailVerificationModal(false);
      sendRequest(false);
      setShow(false);
      toast.error(error?.response?.data?.errors?.[0]?.msg);
    }
  };

  const callToResendOTPAPI = async () => {
    if (
      frmInitialValues?.email &&
      frmInitialValues?.email.trim()
    ) {
      let data = {
        type: "otpRequest",
        email: frmInitialValues?.email,
      };

      const res = await accountCheckEmail(data);
      if (res?.data?.success) {
        let fromdataobj = { ...frmInitialValues };
        fromdataobj.isSubmitEmail = true;
        fromdataobj.type = "validateOTP";
        setFrmInitialValues(fromdataobj);
        startCountDown(); // Start the timer on success
      } else {
        toast.error(res?.data?.errors?.[0]?.msg);
      }
    } else {
      let fromdataobj = { ...frmInitialValues };
      fromdataobj.isSubmitEmail = false;
      fromdataobj.type = "otpRequest";
      setFrmInitialValues(fromdataobj);
      toast.error(t('error_Please_enter_a_valid_email_address'));
    }
  };

  useEffect(() => {
    let timer;
    if (timerCount > 0) {
      timer = setTimeout(() => {
        setTimer(timerCount - 1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [timerCount]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="auth-modal modal fade"
      centered
    >
      <Modal.Body>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <Formik
          enableReinitialize={true}
          initialValues={frmInitialValues}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ errors }) => (
            <Form>
              <div
                className="col-12 justify-content-center align-items-center"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h3 className="bodyBoldFont mb-5 text-center">
                  {t('reward_store.email_verification')}
                </h3>

                {frmInitialValues?.isSubmitEmail && (
                  <span className="mb-5">
                    {t('reward_store.we_sent_otp')}{" "}
                    <span className="bodyBoldFont">
                      {frmInitialValues?.email}
                    </span>
                  </span>
                )}

                <div className="col-6 mb-5">
                  {!frmInitialValues?.isSubmitEmail ? (
                    <>
                      <label htmlFor="email" className="form-label">
                        {t('reward_store.email')}
                      </label>
                      <Field
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder={t('reward_store.enter_email')}
                        name="email"
                        onChange={(e) => {
                          let fromdataobj = { ...frmInitialValues };
                          fromdataobj.email = e.target.value;
                          setFrmInitialValues(fromdataobj);
                        }}
                      />
                      {errors.email ? <div>{errors.email}</div> : null}
                    </>
                  ) : (
                    <>
                      <label htmlFor="otp" className="form-label">
                        {t('reward_store.otp')}
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="otp"
                        placeholder={t('reward_store.enter_otp')}
                        name="otp"
                        onChange={(e) => {
                          let fromdataobj = { ...frmInitialValues };
                          fromdataobj.otp = e.target.value;
                          setFrmInitialValues(fromdataobj);
                        }}
                        maxLength={6}
                        onKeyPress={(e) => {
                          if (new RegExp(/[0-9]/).test(e.key)) {
                          } else e.preventDefault();
                        }}
                      />
                      {errors.otp ? <div>{errors.otp}</div> : null}
                    </>
                  )}
                </div>

                <div className="col-6">
                  <button
                    className="btn btn-primary btn-arrow"
                    style={{ width: "100%" }}
                    type="submit"
                  >
                    {t('crm.submit')}
                  </button>
                  {frmInitialValues?.isSubmitEmail &&
                    <>
                      <div className="links">
                        <h5 className="d11-text-center mt-3">
                          <span className="link-offset-2 link-underline link-underline-opacity-0 verification-link d11-otp-resend">
                            {" "}
                            {t("login.didnt_receive_code")}
                          </span>
                        </h5>
                      </div>
                      <div className="links">
                        <h5 className="d11-text-center">
                          {timerCount === 0 ? (
                            <span
                              className="link-offset-2 link-underline link-underline-opacity-0 verification-link"
                              style={{
                                color: "var(--primary-color-red)",
                                cursor: "pointer",
                                textDecoration: 'underline !important'
                              }}
                              onClick={(e) => callToResendOTPAPI(e)}
                            >
                              <b>{t("login.resend")}</b>
                            </span>
                          ) : null}
                        </h5>
                        <h5 className="d11-text-center">
                          {timerCount !== 0 ? (
                            <span
                              className="link-offset-2 link-underline link-underline-opacity-0 verification-link d11-otp-resend d11-otp-resend-timer"
                            >
                              {t("login.request_one", { n: timerCount })}
                            </span>
                          ) : null}
                        </h5>
                      </div>
                    </>
                  }
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};


export default EmailVerificationModal;
