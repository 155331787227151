import React, { useEffect, useRef, useState } from "react";
import CrossIcon from "../../assets/images/cross-icon-svg.svg";
import CrossIconWhite from "../../assets/images/cross-icon-svg-white.svg";
import TickIconPurple from "../../assets/images/tick-icon-svg-purple.svg";
import TickIconWhite from "../../assets/images/tick-icon-svg-white.svg";
import { func } from "../../Utilities/logFunc";
import { toast } from "react-toastify";
import {
  createStripeSubscriptionTransaction,
  getSubscriptionTypeList,
} from "../../Utilities/controller";
import Master from "../../assets/images/dGames-master.png";
import Champion from "../../assets/images/dGames-champion.png";
import ChampIcon from "../../assets/images/championIcon.png";
import MasterIcon from "../../assets/images/grandMasterIcon.png";
import SuccessPop from "./SuccessPop";
import PaymentModal from "../HomePage/CoinStore/PaymentModal";
import Loader from "../Common/Loader";
import PaymentChooseOption from "./PaymentChooseOption";
import { useProfileDataContext } from "../HomePage/ProfileContext";
import { useTranslation } from "react-i18next";
import moment from "moment";

const SubscriptionTypeList = () => {
  const [list, setList] = useState(null);
  const [showPayment, setShowPayment] = useState(false);
  const [showPaymentOption, setShowPaymentOption] = useState(false);
  const [activeIndex, setActiveIndex] = useState();
  const { liteProfile } = useProfileDataContext();
  const { t } = useTranslation();
  const contentRef = useRef(null);
  const subscriptionDetails = JSON.parse(
    localStorage.getItem("subscriptionDetails")
  );

  const fetchList = async () => {
    let payload = {
      country: liteProfile?.country?._id,
      company: process.env.REACT_APP_COMPANY_ID,
    };
    try {
      const res = await getSubscriptionTypeList(payload);
      func("subscription list 13", res?.data?.data?.[0]?.param?.data);
      setList(res?.data?.data?.[0]?.param?.data);
    } catch (error) {
      toast.error(error?.response?.data?.errors?.[0]?.msg);
    }
  };
  const handlerBuyPlan = async (ind) => {
    setShowPaymentOption(true);
    setActiveIndex(ind);
  };
  useEffect(() => {
    fetchList();
    if (document.documentElement.dir == "rtl" && contentRef?.current) {
      const contentWidth =
        contentRef?.current?.clientWidth - contentRef?.current?.scrollWidth;
      contentRef?.current.scrollTo({
        left: contentWidth,
        behavior: "smooth",
      });
    } else {
      const contentWidth =
        contentRef?.current?.scrollWidth - contentRef?.current?.clientWidth;
      contentRef?.current.scrollTo({
        left: contentWidth,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <div className="content">
      <div className="inner">
        <div class="row gy-5">
          <div class="col-12">
            <div class="mb-4 d-flex align-items-center justify-content-between">
              <h2 class="h3 bodyBoldFont mb-0">
                {t("premium.company_premium")}
              </h2>
            </div>
            <div class="pricingTable" ref={contentRef}>
              <table class="table m-0">
                <thead>
                  <tr>
                    <th width="31%" scope="col"></th>
                    <th width="23%" scope="col">
                      <h4 class="text-white">{list?.[0]?.planName}</h4>
                    </th>
                    <th width="23%" scope="col">
                      <img class="mb-4 icon-100" src={ChampIcon} alt="" />
                      <h4 class="text-white">{list?.[1]?.planName}</h4>
                      {/* <p class="mb-0 text-white">Tier 1</p> */}
                    </th>
                    <th width="23%" scope="col">
                      <img class="mb-4 icon-100" src={MasterIcon} alt="" />
                      <h4 class="text-white">{list?.[2]?.planName}</h4>
                      {/* <p class="mb-0 text-white">Tier 2</p> */}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="4">
                      <h5>{t("premium.features")}</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>{t("premium.prize_pool_multipler")}</td>
                    <td class="bodyHeavyFont">{list?.[0]?.multiplier || 1}x</td>
                    <td class="bodyHeavyFont">
                      {list?.[1]?.multiplier || 1.5}x
                    </td>
                    <td class="text-primary bodyHeavyFont">
                      {list?.[2]?.multiplier || 2}x
                    </td>
                  </tr>
                  <tr>
                    <td>{t("premium.joining")}</td>
                    <td>{t("premium.daily1")}</td>
                    <td>{t("premium.unlimited")}</td>
                    <td class="text-primary">{t("premium.unlimited")}</td>
                  </tr>
                  <tr>
                    <td>{t("premium.statistic")}</td>
                    <td>{t("premium.basic_statistics")}</td>
                    <td>{t("premium.basic_statistics")}</td>
                    <td class="text-primary">
                      {" "}
                      {t("premium.advance_statistics")}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("premium.subscription_badge")}</td>
                    <td>
                      <img src={CrossIcon} alt="" />
                    </td>
                    <td>
                      <img className="icon-75" src={Champion} alt="" />
                    </td>
                    <td>
                      <img className="icon-75" src={Master} alt="" />
                    </td>
                  </tr>
                  <tr>
                    <td>{t("premium.leaderboard_highlight")}</td>
                    <td>
                      <img src={CrossIcon} alt="" />
                    </td>
                    <td>
                      <img src={TickIconWhite} alt="" />
                    </td>
                    <td>
                      <img src={TickIconPurple} alt="" />
                    </td>
                  </tr>
                  <tr>
                    <td>{t("premium.premium_support")}</td>
                    <td>
                      <img src={CrossIcon} alt="" />
                    </td>
                    <td>
                      <img src={CrossIconWhite} alt="" />
                    </td>
                    <td>
                      <img src={TickIconPurple} alt="" />
                    </td>
                  </tr>
                  <tr>
                    <td>{t("premium.exclusive_skins_avatars")}</td>
                    <td>
                      <img src={CrossIcon} alt="" />
                    </td>
                    <td>
                      <img src={CrossIconWhite} alt="" />
                    </td>
                    <td>
                      <img src={TickIconPurple} alt="" />
                    </td>
                  </tr>

                  <tr>
                    <td>{t("premium.ad_free_experience")}</td>
                    <td>
                      <img src={CrossIcon} alt="" />
                    </td>
                    <td>
                      <img src={CrossIconWhite} alt="" />
                    </td>
                    <td>
                      <img src={TickIconPurple} alt="" />
                    </td>
                  </tr>

                  <tr>
                    <td>{t("premium.private_contest_hosting")}</td>
                    <td>
                      <img src={CrossIcon} alt="" />
                    </td>
                    <td>
                      <img src={CrossIconWhite} alt="" />
                    </td>
                    <td>
                      <img src={TickIconPurple} alt="" />
                    </td>
                  </tr>
                </tbody>
                {func(
                  "list 126 *****************",
                  localStorage.getItem("planName")
                )}
                <tfoot>
                  <tr>
                    <td></td>
                    {/* {console.log( liteProfile?.isUserSubscribed)} */}

                    {
                    liteProfile?.isUserSubscribed ? (
                      <>
                      
                       { localStorage.getItem("planName") == ("Free" ||
                        "undefined") ? 
                        <td>
                          <h5 class="mb-3 text-white">
                            {t("tournaments.free")}
                          </h5>
                          <span
                            // onClick={()=> handlerBuyPlan(list[0]?.packs?.[0]?._id,0)}
                            class=" btn btn-outline-golden disabled"
                          >
                            {" "}
                            {t("premium.currently_active")}
                          </span>
                        </td> : 
                        <td>
                        {/* <h5 class="mb-3 text-white">
                          {t("tournaments.free")}
                        </h5>
                        <span
                          // onClick={()=> handlerBuyPlan(list[0]?.packs?.[0]?._id,0)}
                          class=" btn btn-outline-golden disabled"
                        >
                          {" "}
                          { t("premium.subscribe")}
                        </span> */}
                      </td>
                        }
                        {
                           localStorage.getItem("planName") == "D11_CHAMPION" ?
                           <td>
                           <h5 class="mb-3 text-white">
                             {list?.[2]?.currencyID?.symbol}
                             {list?.[2]?.packs?.[0]?.amount}/{" "}
                             {t("premium.month")}
                           </h5>
                           <span
                             onClick={() => handlerBuyPlan(2)}
                             className={`${
                               localStorage.getItem("planName") ==
                               "D11_CHAMPION"
                                 ? "btn btn-outline-golden disabled"
                                 : "btn btn-gradient"
                             } `}
                           >
                             {localStorage.getItem("planName") ==
                             "D11_CHAMPION"
                               ? t("premium.currently_active")
                               : t("premium.subscribe")}
                           </span>
                         </td>
                         :
                         <td>
                         <h5 class="mb-3 text-white">
                           {list?.[1]?.currencyID?.symbol}
                           {list?.[1]?.packs?.[0]?.amount}/{" "}
                           {t("premium.month")}
                         </h5>
                         <span
                           onClick={() => handlerBuyPlan(1)}
                           className=
                                "btn btn-outline-golden disabled"
                            
                         >
                          { t("premium.subscribe")}
                         </span>
                       </td>

                         
                        }
                       
                       {
                        
                          localStorage.getItem("planName") == "D11_GRANDMASTER" ?
                          <td>
                          <h5 class="mb-3 text-white">
                            {list?.[2]?.currencyID?.symbol}
                            {list?.[2]?.packs?.[0]?.amount}/{" "}
                            {t("premium.month")}
                          </h5>
                          <span
                            onClick={() => handlerBuyPlan(2)}
                            className={`${
                              localStorage.getItem("planName") ==
                              "D11_GRANDMASTER"
                                ? "btn btn-outline-golden disabled"
                                : "btn btn-gradient"
                            } `}
                          >
                            {localStorage.getItem("planName") ==
                            "D11_GRANDMASTER"
                              ? t("premium.currently_active")
                              : t("premium.subscribe")}
                          </span>
                        </td>
                        :
                        <td>
                        <h5 class="mb-3 text-white">
                          {list?.[2]?.currencyID?.symbol}
                          {list?.[2]?.packs?.[0]?.amount}/{" "}
                          {t("premium.month")}
                        </h5>
                        <span
                          onClick={() => handlerBuyPlan(2)}
                          className= "btn btn-outline-golden disabled"
                            
                         
                        >
                         
                            { t("premium.subscribe")}
                        </span>
                      </td>
                          
                         
                       }
                      </>
                    ) : (
                      <>
                        {(!liteProfile?.isUserSubscribed &&
                          !subscriptionDetails?.isPremium) ||
                        localStorage.getItem("planName") ==
                          ("Free" || "undefined") ? (
                          <td>
                            <h5 class="mb-3 text-white">
                              {t("tournaments.free")}
                            </h5>
                            <span
                              // onClick={()=> handlerBuyPlan(list[0]?.packs?.[0]?._id,0)}
                              class=" btn btn-outline-golden disabled"
                            >
                              {" "}
                              {t("premium.currently_active")}
                            </span>
                          </td>
                        ) : (
                          <td></td>
                        )}

                        <td>
                          <h5 class="mb-3 text-white">
                            {list?.[1]?.currencyID?.symbol}
                            {list?.[1]?.packs?.[0]?.amount}/{" "}
                            {t("premium.month")}
                          </h5>
                          <span
                            onClick={() => handlerBuyPlan(1)}
                            className={`${
                              localStorage.getItem("planName") == "D11_CHAMPION"
                                ? "btn btn-outline-golden disabled"
                                : "btn btn-gradient"
                            } `}
                          >
                            {localStorage.getItem("planName") == "D11_CHAMPION"
                              ? t("premium.currently_active")
                              : t("premium.subscribe")}{" "}
                          </span>
                        </td>
                        <td>
                          <h5 class="mb-3 text-white">
                            {list?.[2]?.currencyID?.symbol}
                            {list?.[2]?.packs?.[0]?.amount}/{" "}
                            {t("premium.month")}
                          </h5>
                          <span
                            onClick={() => handlerBuyPlan(2)}
                            className={`${
                              localStorage.getItem("planName") ==
                              "D11_GRANDMASTER"
                                ? "btn btn-outline-golden disabled"
                                : "btn btn-gradient"
                            } `}
                          >
                            {localStorage.getItem("planName") ==
                            "D11_GRANDMASTER"
                              ? t("premium.currently_active")
                              : t("premium.subscribe")}
                          </span>
                        </td>
                      </>
                    )}
                  </tr>
                </tfoot>
              </table>
            </div>
            {(subscriptionDetails?.isPremium ||
              liteProfile?.isUserSubscribed) && (
              <>
                <div className="row mt-5">
                  <div className="col-7"></div>
                  <div className="col-5">
                    {
                      // console.log(subscriptionDetails)
                      localStorage.getItem("planNameStatus") == "SUCCESS" && (
                        <div className = "d-flex justify-content-between">
                          <span className="text-light-color">
                            {t("premium.startDate")}:{" "}
                            {moment(subscriptionDetails.startDate).format(
                              "Do MMMM YYYY HH:mm"
                            )}
                          </span>
                          <span style={{ marginLeft: "40px" }}>
                            {t("premium.endDate")}:{" "}
                            {moment(subscriptionDetails.endDate).format(
                              "Do MMMM YYYY HH:mm"
                            )}
                          </span>
                        </div>
                      )
                    }

                    {/* <p className='text-light-color mt-3'>Notes: {subscriptionDetails.isPremium ? 'You are subscribed' : 'You are not subscribe to premium yet. Please recharge your Cellcard credits for the same'}</p> */}
                    {subscriptionDetails?.isPremium &&
                    liteProfile?.isUserSubscribed ? (
                      <p className="text-light-color mt-3">
                        {t("premium.notes")} : {t("premium.subscribing")}
                      </p>
                    ) : !subscriptionDetails?.isPremium &&
                      liteProfile?.isUserSubscribed ? (
                      <p className="text-light-color mt-3">
                        {t("premium.notes")} : {t("premium.suspended")}
                      </p>
                    ) : subscriptionDetails?.isPremium &&
                      !liteProfile?.isUserSubscribed ? (
                      <p className="text-light-color mt-3">
                        {t("premium.notes")} : {t("premium.resubscribe")}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {<SuccessPop />}
        {showPaymentOption && (
          <PaymentChooseOption
            showPaymentOption={showPaymentOption}
            setShowPaymentOption={setShowPaymentOption}
            list={list?.[activeIndex]}
          />
        )}
      </div>
    </div>
  );
};

export default SubscriptionTypeList;
