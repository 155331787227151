import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import LOGO from './images/TALABATlogo.png'
import { useTranslation } from 'react-i18next'
const Footer = () => {
const navigate = useNavigate()
const {t} = useTranslation()
  return (
   <div class="footer">
   <footer class=" container d-flex justify-content-center">
      <div class="row footer-area">
         <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div class="footer-content">
            <a href="#" class=" mb-4">
            <img src={LOGO} class="logo-img mb-4" alt="" />
            </a>
            <p >{t('landing_page.footer_line1')}</p>
            <p >{t('landing_page.footer_line2')}</p>
            <p >{t('landing_page.let_play')}</p>
            <p >{t('landing_page.copyright')}</p>
            </div>
         </div>
         <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div class="row">
               <div class="col-lg-6 col-md-12 col-sm-12">
                  <div class="footer-content">
                     <h4 class="title_secondary mb-4">{t('landing_page.explore')}</h4>
                     <address class="">
                        <ul class="footer-nav">
                           {/* <li>
                              <a class="footer-link active" href="#">Home</a>
                           </li> */}
                           {/* <li>
                              <a class="footer-link" href="#">About Us</a>
                           </li>
                           <li>
                              <a class="footer-link" href="#">Games</a>
                           </li>
                           <li>
                              <a class="footer-link" target="_blank" href="policy">Offers</a>
                           </li>
                           <li>
                              <a class="footer-link" target="_blank" href="termsAndCondition">Media Center</a>
                           </li>
                           <li>
                              <a class="footer-link" href="#">Contact Us</a>
                           </li> */}
                           <li>
                              <span class="footer-link"  onClick={()=> {navigate('/login',{state:1})}}>{t('landing_page.register')}</span>
                           </li>
                           <li>
                              <span class="footer-link"  onClick={()=> {navigate('/login',{state:0})}}>{t('landing_page.login')}</span>
                           </li>
                        
                        </ul>
                     </address>
                  </div>
               </div>
               <div class="col-lg-6 col-md-12 col-sm-12">
                  <div class="footer-content">
                  {/* <nav class="">
                     <h4 class="title_secondary mb-4">Connect</h4>
                     <ul class="footer-nav mb-4">
                        <li>
                           <a class="footer-link" href="#">Partners</a>
                        </li>
                        <li>
                           <a class="footer-link" href="#">Contact Us</a>
                        </li>
                     </ul>
                  </nav> */}
                  <h4 class="title_secondary mb-4">{t('landing_page.follow_us')}</h4>
                  <ul class="social-links">
                     {/* <li>
                        <a class="footer-link" href="#">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                              <g clip-path="url(#clip0_3879_97156)">
                                 <path d="M15.432 11.9391C15.432 12.6711 14.892 13.2711 14.208 13.2711C13.536 13.2711 12.984 12.6711 12.984 11.9391C12.984 11.2071 13.524 10.6071 14.208 10.6071C14.892 10.6071 15.432 11.2071 15.432 11.9391ZM9.828 10.6071C9.144 10.6071 8.604 11.2071 8.604 11.9391C8.604 12.6711 9.156 13.2711 9.828 13.2711C10.512 13.2711 11.052 12.6711 11.052 11.9391C11.064 11.2071 10.512 10.6071 9.828 10.6071ZM22.5 3.01106V24.5391C19.4768 21.8675 20.4437 22.7518 16.932 19.4871L17.568 21.7071H3.96C2.604 21.7071 1.5 20.6031 1.5 19.2351V3.01106C1.5 1.64306 2.604 0.539062 3.96 0.539062H20.04C21.396 0.539062 22.5 1.64306 22.5 3.01106ZM19.08 14.3871C19.08 10.5231 17.352 7.39106 17.352 7.39106C15.624 6.09506 13.98 6.13106 13.98 6.13106L13.812 6.32306C15.852 6.94706 16.8 7.84706 16.8 7.84706C13.9495 6.28477 10.6011 6.28448 7.836 7.49906C7.392 7.70306 7.128 7.84706 7.128 7.84706C7.128 7.84706 8.124 6.89906 10.284 6.27506L10.164 6.13106C10.164 6.13106 8.52 6.09506 6.792 7.39106C6.792 7.39106 5.064 10.5231 5.064 14.3871C5.064 14.3871 6.072 16.1271 8.724 16.2111C8.724 16.2111 9.168 15.6711 9.528 15.2151C8.004 14.7591 7.428 13.7991 7.428 13.7991C7.60453 13.9226 7.89563 14.0828 7.92 14.0991C9.94547 15.2333 12.8226 15.605 15.408 14.5191C15.828 14.3631 16.296 14.1351 16.788 13.8111C16.788 13.8111 16.188 14.7951 14.616 15.2391C14.976 15.6951 15.408 16.2111 15.408 16.2111C18.06 16.1271 19.08 14.3871 19.08 14.3871Z" fill="white"/>
                              </g>
                              <defs>
                                 <clipPath id="clip0_3879_97156">
                                    <rect width="24" height="24" fill="white" transform="translate(0 0.539062)"/>
                                 </clipPath>
                              </defs>
                           </svg>
                        </a>
                     </li> */}
                     <li>
                        <a class="footer-link" href="https://www.tiktok.com/@talabat?lang=en">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                              <g clip-path="url(#clip0_3879_97159)">
                                 <path d="M22.5017 10.3786C20.438 10.3835 18.425 9.73994 16.7469 8.53875V16.9163C16.7463 18.4679 16.2721 19.9823 15.3875 21.2571C14.503 22.5319 13.2504 23.5063 11.7972 24.0499C10.3439 24.5936 8.75933 24.6806 7.25529 24.2994C5.75124 23.9182 4.39943 23.0869 3.3806 21.9167C2.36177 20.7464 1.72449 19.293 1.55397 17.7508C1.38345 16.2086 1.68783 14.6511 2.42639 13.2866C3.16495 11.922 4.30251 10.8155 5.68694 10.1149C7.07137 9.41427 8.63669 9.15304 10.1736 9.36609V13.5797C9.47031 13.3585 8.7151 13.3651 8.01582 13.5987C7.31654 13.8323 6.70896 14.2809 6.27983 14.8804C5.8507 15.4799 5.62197 16.1997 5.62632 16.9369C5.63066 17.6742 5.86785 18.3912 6.30401 18.9856C6.74017 19.58 7.353 20.0214 8.05498 20.2468C8.75696 20.4721 9.51219 20.4699 10.2128 20.2404C10.9135 20.0109 11.5237 19.5659 11.9563 18.9689C12.389 18.3719 12.6219 17.6535 12.6219 16.9163V0.539062H16.7469C16.744 0.887409 16.7732 1.23528 16.8341 1.57828C16.9774 2.34398 17.2754 3.07238 17.7099 3.71894C18.1445 4.3655 18.7063 4.91662 19.3611 5.33859C20.2927 5.95457 21.3849 6.28289 22.5017 6.28266V10.3786Z" fill="white"/>
                              </g>
                              <defs>
                                 <clipPath id="clip0_3879_97159">
                                    <rect width="24" height="24" fill="white" transform="translate(0 0.539062)"/>
                                 </clipPath>
                              </defs>
                           </svg>
                        </a>
                     </li>
                     <li>
                        <a class="footer-link" href="https://www.instagram.com/talabatbh/?hl=en or https://www.instagram.com/Talabat/">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                              <path d="M12.0043 7.14883C9.02305 7.14883 6.61836 9.55352 6.61836 12.5348C6.61836 15.516 9.02305 17.9207 12.0043 17.9207C14.9855 17.9207 17.3902 15.516 17.3902 12.5348C17.3902 9.55352 14.9855 7.14883 12.0043 7.14883ZM12.0043 16.0363C10.0777 16.0363 8.50273 14.466 8.50273 12.5348C8.50273 10.6035 10.073 9.0332 12.0043 9.0332C13.9355 9.0332 15.5059 10.6035 15.5059 12.5348C15.5059 14.466 13.9309 16.0363 12.0043 16.0363ZM18.8668 6.92852C18.8668 7.62695 18.3043 8.18477 17.6105 8.18477C16.9121 8.18477 16.3543 7.62227 16.3543 6.92852C16.3543 6.23477 16.9168 5.67227 17.6105 5.67227C18.3043 5.67227 18.8668 6.23477 18.8668 6.92852ZM22.434 8.20352C22.3543 6.5207 21.9699 5.03008 20.7371 3.80195C19.509 2.57383 18.0184 2.18945 16.3355 2.10508C14.6012 2.00664 9.40273 2.00664 7.66836 2.10508C5.99023 2.18477 4.49961 2.56914 3.2668 3.79727C2.03398 5.02539 1.6543 6.51602 1.56992 8.19883C1.47148 9.9332 1.47148 15.1316 1.56992 16.866C1.64961 18.5488 2.03398 20.0395 3.2668 21.2676C4.49961 22.4957 5.98555 22.8801 7.66836 22.9645C9.40273 23.0629 14.6012 23.0629 16.3355 22.9645C18.0184 22.8848 19.509 22.5004 20.7371 21.2676C21.9652 20.0395 22.3496 18.5488 22.434 16.866C22.5324 15.1316 22.5324 9.93789 22.434 8.20352ZM20.1934 18.727C19.8277 19.6457 19.1199 20.3535 18.1965 20.7238C16.8137 21.2723 13.5324 21.1457 12.0043 21.1457C10.4762 21.1457 7.19023 21.2676 5.81211 20.7238C4.89336 20.3582 4.18555 19.6504 3.81523 18.727C3.2668 17.3441 3.39336 14.0629 3.39336 12.5348C3.39336 11.0066 3.27148 7.7207 3.81523 6.34258C4.18086 5.42383 4.88867 4.71602 5.81211 4.3457C7.19492 3.79727 10.4762 3.92383 12.0043 3.92383C13.5324 3.92383 16.8184 3.80195 18.1965 4.3457C19.1152 4.71133 19.823 5.41914 20.1934 6.34258C20.7418 7.72539 20.6152 11.0066 20.6152 12.5348C20.6152 14.0629 20.7418 17.3488 20.1934 18.727Z" fill="white"/>
                           </svg>
                        </a>
                     </li>
                     <li>
                        <a class="footer-link" href="https://www.facebook.com/TALABATBH">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                              <g clip-path="url(#clip0_3879_97165)">
                                 <path d="M23.625 12.5391C23.625 6.11719 18.4219 0.914062 12 0.914062C5.57812 0.914062 0.375 6.11719 0.375 12.5391C0.375 18.3412 4.62609 23.1506 10.1836 24.0234V15.8995H7.23047V12.5391H10.1836V9.97781C10.1836 7.06453 11.918 5.45531 14.5744 5.45531C15.8466 5.45531 17.1769 5.68219 17.1769 5.68219V8.54156H15.7106C14.2669 8.54156 13.8164 9.43781 13.8164 10.357V12.5391H17.0405L16.5248 15.8995H13.8164V24.0234C19.3739 23.1506 23.625 18.3412 23.625 12.5391Z" fill="white"/>
                              </g>
                              <defs>
                                 <clipPath id="clip0_3879_97165">
                                    <rect width="24" height="24" fill="white" transform="translate(0 0.539062)"/>
                                 </clipPath>
                              </defs>
                           </svg>
                        </a>
                     </li>
                  </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </footer>
</div>
  )
}

export default Footer