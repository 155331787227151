import React, { useEffect, useReducer, useState } from "react";
import BaseAuth from "./BaseAuth";
import {
  forgetPin,
  getCountryList,
  getJson,
  getLanguage,
  requestApplySignupCode,
  userRequestOTP,
  userRequestOTPSignUp,
} from "../../Utilities/controller";
import { func } from "../../Utilities/logFunc";
import Loader from "../Common/Loader";
import { url } from "../../Utilities/url";
import { errors } from "../../Utilities/error";
import { useNavigate } from "react-router";
import { routes } from "../../Utilities/routesFb";
import { INITIAL_STATE, loginReducer } from "./loginReducer";
import LandingPage from "../LandingPage/LandingPage";
import { INITIAL_STATE_REG, registerReducer } from "./SignupReducer";
import Select from "react-select";
import DropDown from "../Common/DropDown";
import CountryDropDown from "../Common/CountryListDropDown";
import { logout } from "../../Utilities/logout";
import { toast } from "react-toastify";
import LoginLandingPage from "../LandingPage/LoginLandingPage";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import i18next from "i18next";
import Cookies from "js-cookie";
import { async } from "q";
const ForgetPin = () => {
  func("login", "login page");
  const [countryList, setCountryList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loginInput, dispatchInput] = useReducer(loginReducer, INITIAL_STATE);
  const [registerInput, dispatchRegisterInput] = useReducer(
    registerReducer,
    INITIAL_STATE_REG
  );
  const [error, setError] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [appliedCouponCode, setAppliedCouponCode] = useState(undefined);
  const [couponError, setCouponError] = useState("");
  const [couponSuccess, setCouponSuccess] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [signupError, setSignupError] = useState("");
  const [onAgree, setOnAgree] = useState(false);
  const [tncError, setTncError] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const [lang, setLang] = useState();
  const [languages, setLanguages] = useState();
  const [isBusy, setIsBusy] = useState(false)
  const fetchCountry = async () => {
    setLoader(true);
    try {
      const data = await getGeoInfo();
      const res = await getCountryList();
      let result = []
      if (res) 
      {
       
        setCountryList((prev) => res?.list);
      }
      let cnty = data?.country_calling_code || "+973"
      // setCountryOption(prev=> res?.list[2].dialingCode)
      let index = null;
      // data.country_calling_code  = undefined
      // console.log("countryCode********", data.country_calling_code)
      res?.list?.filter((item, ind) => {
        if (item?.dialingCode == cnty ) {
          index = ind;
          return index;
        }
      });
      if(index <=-1 || index== null){
        res?.list?.filter((item, ind) => {
          if (item?.dialingCode == "+973" ) {
            index = ind;
            return index;
          }
        });
      }
      func("index", index);
      setIndex(index);
      // console.log(res?.list?.[index]?.dialingCode)
      dispatchInput({
        type: "CHANGE_INPUT",
        payload: {
          name: "countryOption",
          value: res?.list?.[index]?.dialingCode,
        },
      });
      dispatchInput({
        type: "CHANGE_INPUT",
        payload: { name: "code", value: res?.list?.[index]?._id },
      });
      dispatchRegisterInput({
        type: "CHANGE_INPUT",
        payload: {
          name: "countryOption",
          value: res?.list?.[index]?.dialingCode,
        },
      });
      dispatchRegisterInput({
        type: "CHANGE_INPUT",
        payload: { name: "code", value: res?.list?.[index]?._id },
      });
    } catch (error) {
      func("error", error);
    }
    setLoader(false);
  };
  const getGeoInfo = async () => {
    let data = {};
    await axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        data = response.data;
        // console.log(
        //       data.ip,
        //       data.country_name,
        //     data.country_calling_code,
        //      data.city,
        //     data.timezone)
        setCountryCode(data.country_calling_code);
      })

      .catch((error) => {
        // console.log(error);
      });
    return data;
  };
  const fetchLanguage = async () => {
    const body = {
      companyId: "656dc321bd70fe027ee3f073",
    };
    try {
      const res = await getLanguage(body);
      // console.log(res)
      setLanguages(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const currentLang = Cookies.get("i18next");
  useEffect(() => {
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(success, error);
    // } else {
    //   console.log("Geolocation not supported");
    // }

    // function success(position) {
    //   console.log("position", position)
    //   const latitude = position.coords.latitude;
    //   const longitude = position.coords.longitude;
    //   console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    // }

    // function error() {
    //   console.log("Unable to retrieve your location");
    // }

    fetchCountry();
    fetchLanguage();
    document.documentElement.dir = currentLang == "ar" ? "rtl" : "ltr";
    document.title = t("title");
  }, [currentLang]);
  const onChangeHandler = (e) => {
    setIsBusy(false)
    setError("")
    dispatchInput({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  const onSignupChangeHandler = (e) => {
    dispatchRegisterInput({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };
  const loginHandler = (e) => {
    e.preventDefault();
    setError("")
    setIsBusy(true)
    if (!loginInput?.mobileNumber || loginInput?.mobileNumber?.length == 0) {
      setError(t('error_enter_phone'));
    } else if (loginInput?.countryOption == "+91") {
      if (loginInput?.mobileNumber.length != 10) {
        // console.log("error*********",)
        setError(t('error_enter_valid_phone'));
        setIsBusy(false)
      } else {
        callToOTPRequestAPI();
        
      }
    } else {
      if (loginInput?.mobileNumber.length < 8) {
        setError(t('error_enter_valid_phone'));
        setIsBusy(false)
      } else {
        callToOTPRequestAPI();
      }
    }

    // else if (loginInput?.mobileNumber?.length != 10) {
    //   setError(errors?.error_enter_valid_phone);
    // } else {
    //   callToOTPRequestAPI();
    // }
    // const onSignupChangeHandler = (e) => {
    //   dispatchRegisterInput({ type: 'CHANGE_INPUT', payload: { name: e.target.name, value: e.target.value } })
    // }
    // const loginHandler = (e) => {
    //   e.preventDefault()
    //   if (!loginInput?.mobileNumber || loginInput?.mobileNumber?.length == 0) {
    //     setError(t('error_enter_phone'))
    //   }
    //   else if (loginInput?.mobileNumber?.length != 10) {
    //     setError(errors?.error_enter_valid_phone)
    //   }
    //   else {
    //     callToOTPRequestAPI();

    //   }
    // }
  };

  // request OTP after login
  const callToOTPRequestAPI = async () => {
    var payload = {
      type: "otpRequest",
      username: loginInput?.mobileNumber,
      phoneCode: loginInput?.countryOption,
      country: loginInput?.code,
    };

    localStorage.setItem("loginInfo", JSON.stringify(payload));
    localStorage.setItem("country", loginInput?.code);
    setLoading(true);
    try {
      const res = await forgetPin(payload);
      func("login res", res?.response?.data?.errors?.[0]?.msg);
      //   console.log(res)
      
      if (res?.data?.success) {
        setIsBusy(false)
        navigate("/" + routes.otp, { state: loginInput });
        
      } else {
        setIsBusy(false)
        setError(res?.response?.data?.errors?.[0]?.msg);
      }
    } catch (error) {
      func("userRequestOTP error", error);
      setIsBusy(false)
      setError(error?.response?.data?.errors?.[0]?.msg);
    }
    setLoading(false);
  };

  const handlerSelect = (e) => {
    setCountryCode(e);
    dispatchInput({
      type: "CHANGE_INPUT",
      payload: { name: "code", value: e?._id },
    });
    dispatchInput({
      type: "CHANGE_INPUT",
      payload: { name: "countryOption", value: e?.dialingCode },
    });
  };
  const handlerSelectRegister = (e) => {
    setCountryCode(e);
    func("onChange");
    setCouponError("");
    setCouponSuccess("");
    setCouponCode("");
    dispatchRegisterInput({
      type: "CHANGE_INPUT",
      payload: { name: "code", value: e?._id },
    });
    dispatchRegisterInput({
      type: "CHANGE_INPUT",
      payload: { name: "countryOption", value: e?.dialingCode },
    });
  };
  // request OTP after login

  // Registration
  const checkValidation = (e) => {
    e.preventDefault();
    if (
      !registerInput?.mobileNumber ||
      registerInput?.mobileNumber?.length == 0
    ) {
      setSignupError(t('error_enter_phone'));
      return;
    } else {
      if (registerInput?.countryOption == "+91") {
        if (registerInput?.mobileNumber.length != 10) {
          setSignupError(t('error_enter_valid_phone'));
          return;
        } else {
          setError("");
        }
      } else {
        if (registerInput?.mobileNumber.length < 8) {
          setSignupError(t('error_enter_valid_phone'));
          return;
        }
      }
    }

    if (onAgree) {
      setTncError("");
      callToOTPRequestAPISignup();
    } else {
      setTncError(errors?.error_agree_terms_and_conditions);
    }
  };

  // Update the state based on whether the checkbox is checked or not
  const handleCheckboxChange = (e) => {
    setOnAgree(e.target.checked);
  };

  // call signup API
  const callToOTPRequestAPISignup = async () => {
    if (!registerInput?.countryOption) {
      setSignupError(t('error_select_country_code'));
      return;
    }
    var payload = {
      type: "otpRequest",
      username: registerInput?.mobileNumber,
      phoneCode: registerInput?.countryOption,
      country: registerInput?.code,
      code: couponCode,
    };
    if (appliedCouponCode) {
      payload.code = couponCode;
    }
    setLoading(true);
    func("signup payload", payload);
    try {
      const res = await userRequestOTPSignUp(payload);
      func("register res", res);
      if (res?.success) {
        navigate("/" + routes.signupotp, { state: registerInput });
      } else {
        setSignupError(res?.response?.data?.errors[0]?.msg);
      }
    } catch (error) {
      func("signup error", error);
      if (
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_expired"
      )
        logout();
      toast.error(error?.response?.data?.errors?.[0]?.msg);
      setSignupError(error?.response?.data?.errors?.[0]?.msg);
    }
    setLoading(false);
  };

  // call coupon apply API
  const applySingupCode = async (e) => {
    e.preventDefault();
    if (couponCode == "") {
      setCouponError(t('error_enter_coupon_code'));
    } else {
      setLoading(true);
      try {
        const res = await requestApplySignupCode({
          code: couponCode,
          type: "signupCode",
          country: registerInput.code,
        });
        func("applySingupCode res", res);
        // setAppliedCouponCode(res.item)
        if (res?.success) {
          setCouponError("");
          setCouponSuccess(t('login.signup_code_apply_success'));
        }
        if (res?.response.data.errors) {
          setCouponError(res?.response?.data?.errors?.[0]?.msg);
        }
      } catch (error) {
        func("error", error);
        if (
          error?.response?.data?.errors?.[0]?.code ==
            "middlewares.token_parser.validation.token_invalid" ||
          error?.response?.data?.errors?.[0]?.code ==
            "middlewares.token_parser.validation.token_expired"
        )
          logout();
        setSignupError(error?.response?.data?.errors?.[0]?.msg);
      }
      setLoading(false);
    }
  };

  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : "#fff",
      backgroundColor: state.isSelected
        ? "var(--secondary-color)!important"
        : "purple",
      height: "34px",
      "&:hover": {
        color: "var(--accent-color)",
      },
      padding: "0px 0px",
      borderRadius: "20px",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "var(--secondary-color)",
      padding: "8px",
      border: "none",
      boxShadow: "none",
      borderRadius: "50px",
      display: "flex",
      alignItems: "center",
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      width: "70px",
      marginRight: "5px",
      padding: "6px",
    }),
    indicatorsSeparator: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      display: "none",
    }),
    indicatorContainer: (defaultStyles) => ({
      ...defaultStyles,
      padding: "0",
      backgroundColor: "red",
    }),

    input: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "30px",
    }),
    dropdownIndicator: (defaultStyles) => ({
      ...defaultStyles,
      color: "var(--color-white)",
    }),
  };

  const { t } = useTranslation();

  const onChangeLang = async (e) => {
    setLoader(true);
    const lang_code = e.toLowerCase();
    // console.log(lang_code)
    i18next.changeLanguage(lang_code);
    localStorage.setItem("i18nextLng", lang_code);
    window.location.href = "/login";
    setLoader(false);
  };
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <LoginLandingPage>
          <BaseAuth>
            <div className="col-4 text-end"></div>
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-7 col-sm-9">
                <h2 class="text-nowrap verification_heading d11-codecpro d11-otp-text text-center lh-1 mb-4">
                  {t("login.forgot_pin")}
                </h2>
                <div className="tab-content auth-content">
                  {/* LOGIN TAB*/}
                  <div
                    className="tab-pane fade show active"
                    id="login-tab-pane"
                    role="tabpanel"
                    aria-labelledby="login-tab"
                    tabindex="0"
                  >
                    <form className="row justify-content-center">
                      <div className="col-4">
                        <label
                          for="inputCoutryCode"
                          className="form-label text-nowrap"
                        >
                          {t("login.country_code")}
                        </label>

                        {countryList?.length == 1 ?
                        <div
                              className="single-country" 
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span class="icon ">
                                <img
                                   class="me-1 flag "
                                  // style={{
                                  //   borderRadius: "50%",
                                  //   width: "24px",
                                  //   height: "24px",
                                  //   marginRight: "5px",
                                  // }}
                                  src={url.imageUrl + countryList?.[0]?.flag?.default}
                                  alt=""
                                />
                              </span>{" "}
                              {countryList?.[0]?.dialingCode}
                            </div>                            
                            :
                            <Select
                          styles={customStyles}
                          name="countryOption"
                          // value={countryCode}
                          defaultValue={countryList?.[index]}
                          options={countryList}
                          onChange={(e) => handlerSelect(e)}
                          getOptionLabel={(ele) => (
                            <div
                              className="d11-menu"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span class="icon">
                                <img
                                   class="me-1"
                                  src={url.imageUrl + ele?.flag?.default}
                                  alt=""
                                />
                              </span>{" "}
                              {ele?.dialingCode}
                            </div>
                          )}
                          
                        />}
                        {/* <CountryDropDown options={countryList} /> */}
                      </div>

                      <div className="col-8">
                        <label for="inputMobileNumber" className="form-label">
                          {t("login.mobile_number")}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="inputMobileNumber"
                          placeholder={t("login.enter_mobile_number")}
                          name="mobileNumber"
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-"].includes(e.key) &&
                            e.preventDefault()
                          }
                          onChange={(e) => onChangeHandler(e)}
                          maxLength={14}
                          value={loginInput?.mobileNumber.slice(0, 14)}
                        />
                      </div>

                      <div className="col-12 d-grid">
                        <button
                          className="btn btn-primary btn-arrow"
                          onClick={(e) => loginHandler(e)}
                          disabled={isBusy}
                        >
                          {t("crm.next")}
                        </button>
                        {error && (
                          <p className="error" style={{ textAlign: "center" }}>
                            {error}
                          </p>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </BaseAuth>
        </LoginLandingPage>
      )}
    </>
  );
};
export default ForgetPin;
