import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { url } from "../../../Utilities/url";
import { func } from "../../../Utilities/logFunc";
import { useTranslation } from "react-i18next";

const WinningBreakUp = ({
  showWinningModal,
  setShowWinningModal,
  selectedTournament,
}) => {
  const [show, setShow] = useState(showWinningModal);
  const {t} = useTranslation()
  const handleClose = () => {
    setShowWinningModal((prev) => false);
    setShow(false);
  };
  func("tournament wining", selectedTournament)
  let prizePooInfo = selectedTournament?.prizePooInfo
    ? selectedTournament?.prizePooInfo
    : selectedTournament?.prizePoolInfo;

  let currency = selectedTournament?.currency?.[0]
    ? selectedTournament?.currency?.[0]
    : selectedTournament?.currency;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="winningBreakupModal modal fade"
      centered
    >
      <Modal.Body style={{minHeight:'31.25'}}>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div class="content text-center">
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="title">
                <h3 class="text-center bodyBoldFont mb-5 pb-1">
                {t("contest_details.winning_breakup")}
                </h3>
              </div>
              <div class="row justify-content-center">
                <div class="col-xl-6 col-lg-7 col-9">
                  {selectedTournament?.pointpool?.length > 0 && (
                    <ul
                      class="nav nav-pills mx-xxl-4 mx-xl-5 mx-3 d-flex justify-content-between auth-nav"
                      id="myTab"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation"  style={{width: '50%'}}>
                        <button
                          class="nav-link active"
                          id="prizePool-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#prizePool-tab-pane"
                          type="button"
                          role="tab"
                          style={{width: '100%'}}
                          aria-controls="prizePool-tab-pane"
                          aria-selected="true"
                        >
                          {t("contest_details.prize_pool")}
                        </button>
                      </li>

                      <li class="nav-item" role="presentation"  style={{width: '50%'}}>
                        <button
                          class="nav-link"
                          id="points-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#points-tab-pane"
                          type="button"
                          role="tab"
                          style={{width: '100%'}}
                          aria-controls="points-tab-pane"
                          aria-selected="false"
                        >
                           {t("contest_details.points")}
                        </button>
                      </li>
                    </ul>
                  )}
                </div>
                <div class="col-xl-10 col-12">
                  <div class="tab-content auth-content">
                    <div
                      class="tab-pane fade show active"
                      id="prizePool-tab-pane"
                      role="tabpanel"
                      aria-labelledby="prizePool-tab"
                      tabindex="0"
                    >
                      <h3 class="mb-3"> {t("contest_details.prize_pool")}</h3>
                      {
                        !selectedTournament?.rewardDisplayText ? 
                        <h4 class="h3 d-flex align-items-center justify-content-center bodyBoldFont poolPrizeBox">
                        <span class="icon me-2" 
                        // style={{display:'flex', justifyContent:'center', alignItems:'center'}}
                        >
                          <img
                            // style={{ width: "35px" }}
                            className="icon-30"
                            src={
                              url?.imageUrl +
                              currency?.outCurrency?.img?.default
                            }
                            alt=""
                          />
                        </span>
                        {selectedTournament?.prizePool || 0}
                      </h4>
                      :
                      <h4>{selectedTournament?.rewardDisplayText}</h4>
                      }
                     
                      <ul class="list-unstyled p-0 m-0 rankList">
                        {prizePooInfo?.map((item) => (
                          <li class="d-flex justify-content-between">
                            <h4 class="mb-0">
                              {/* Rank: {item?.rankFrom} - {item?.rankTo} */}
                              {t("contest_details.rank")}: {item?.rankFrom}{(item.rankTo !== '0' && item.rankFrom != item.rankTo) && "-"}{(item.rankTo !== '0' && item.rankFrom != item.rankTo) && item?.rankTo}

                            </h4>
                            <h4 class="mb-0  justify-content-center align-items-center">
                              {
                                !selectedTournament?.rewardDisplayText &&  <span class="icon me-2">
                                <img
                                  // style={{ width: "1.5625rem" }}
                                  className="icon-20"

                                  src={
                                    url?.imageUrl +
                                    currency?.outCurrency?.img?.default
                                  }
                                  alt=""
                                
                                />
                              </span>
                              }
                             
                              <span>{item?.prize || item?.amount}</span>
                            </h4>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {selectedTournament?.pointpool?.length > 0 && (
                      <div
                        class="tab-pane fade"
                        id="points-tab-pane"
                        role="tabpanel"
                        aria-labelledby="points-tab"
                        tabindex="0"
                      >
                        <ul class="list-unstyled p-0 m-0 rankList">
                          {selectedTournament?.pointpool?.map((item) => (
                            <li class="d-flex justify-content-between">
                              <h4 class="mb-0">
                              {t("contest_details.rank")}: {item?.rankFrom} {(item.rankTo !== '0' && item.rankFrom != item.rankTo) && "-"} {(item.rankTo !== '0' && item.rankFrom != item.rankTo) && item?.rankTo}
                              </h4>
                              <h4 class="mb-0">{item?.point}</h4>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default WinningBreakUp;
