// firebaseAnalytics.js
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent ,setUserId} from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyCaZ7GEsZmwcyL9Pu0ebpO4QbNs14robds",
  authDomain: "talabat-63bc7.firebaseapp.com",
  projectId: "talabat-63bc7",
  storageBucket: "talabat-63bc7.appspot.com",
  messagingSenderId: "324116194999",
  appId: "1:324116194999:web:2c9dff64740bd215164f32",
  measurementId: "G-YEKS88JZ8H"
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

//setUserId(analytics, "GU-222222");

//log event
const eventTracking=(event,parameters)=>{
  //TLB_UserID, TLB_TimeStamp
  var currentDate = new Date().toTimeString();
  var profileData = JSON.parse(localStorage.getItem("profile"));
  var customParam ={...parameters,TLB_TimeStamp:currentDate };
  if(profileData!=null && profileData?.item?.user_id){
    customParam.TLB_UserID = profileData?.item?.user_id;
  }else if(profileData!=null && profileData?.item?._id){
    customParam.TLB_UserID = profileData?.item?._id;
  }
  console.log("Event Triggered",event,customParam)
  logEvent(analytics, event, customParam);
}

export { analytics, logEvent,eventTracking };