import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Error from "../../Common/Error";
import { encryptKey } from "../../../Utilities/encryptDecrypt";
import { changePin } from "../../../Utilities/controller";
import { useProfileDataContext } from "../ProfileContext";
import { toast } from "react-toastify";
import { func } from "../../../Utilities/logFunc";
import { useTranslation } from "react-i18next";
import Loader from "../../Common/Loader";
import {eventTracking} from "../../../firebaseAnalytics"; 
import {events} from "../../../Utilities/appEvents"; 
const ChangePin = ({ showChangePin, setShowChangePin }) => {
  const {t} = useTranslation()
  const [show, setShow] = useState(showChangePin);
  const [oldPin, setOldPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [error, setError] = useState({
    oldPin: "",
    newPin: "",
    confirmPin: "",
  });
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { profileData } = useProfileDataContext();

  const handleClose = () => {
    setShowChangePin(false);
    setShow(false);
  };

  const callChangePinAPI = async () => {
    setLoading(true);
    const encOldPin = await encryptKey(oldPin);
    const encNewPin = await encryptKey(newPin);

    var payload = {
      username: profileData?.item?.gamerjiName,
      newPin: encNewPin,
      confirmPin: encNewPin,
      oldPin: encOldPin,
    };
    
    try {
      const res = await changePin(payload);
      func(res);
      if (res?.data?.success) {
        eventTracking(events.UPDATE_PIN,{
          TLB_NewPin: encNewPin
        });
        // console.log("change pin success", res?.data?.msg);
        setLoading(false);
        toast.success(res?.data?.msg);
        handleClose();
      } else {
        setLoading(false);
        func("change pin error", res);
      }
    } catch (error) {
      // toast.error(error?.response?.data?.message);
      setLoading(false);
      setError({...error, confirmPin: error?.response?.data?.message})
    }
  };

  const validatePins = () => {
    console.log("validate")
    let valid = true;
    const newErrors = { oldPin: "", newPin: "", confirmPin: "" };

    if (!oldPin) {
      newErrors.oldPin = t('error_pin_old');
      valid = false;
    }
   
    
        if (!newPin) {
            newErrors.newPin = t('error_pin_new');
            valid = false;
          }
        if (!confirmPin) {
            newErrors.confirmPin = t('error_pin_confirm');
            valid = false;
        }
        if (newPin !== confirmPin) {
            newErrors.confirmPin = t('new_pin_confirm_pin_not_match');
            valid = false;
        }
    
    console.log(newErrors)
    setError({...newErrors});
    setIsValid(valid);
  };

  const handlerSubmit = (e) => {
    console.log(oldPin, newPin, confirmPin)
    let newErrors = {}
    let valid = true
    e.preventDefault();
    if (!oldPin) {
      newErrors.oldPin = t('error_pin_old');
      valid = false;
    }
    else if (!newPin) {
      newErrors.newPin = t('error_pin_new');
      valid = false;
    }
  else if (!confirmPin) {
      newErrors.confirmPin = t('error_pin_confirm');
      valid = false;
  }
  else if (oldPin.length !== 6 ) {
    newErrors.oldPin = t('error_pin_length');
    valid = false;
}
else if (newPin.length !==6 ) {
  newErrors.newPin = t('error_pin_length');
  valid = false;
}
else if (confirmPin.length !== 6) {
  newErrors.confirmPin = t('error_pin_length');
  valid = false;
}
  else if (newPin !== confirmPin) {
      newErrors.confirmPin = t('new_pin_confirm_pin_not_match');
      valid = false;
  }
  console.log(valid)
      console.log(newErrors)

    if (valid && confirmPin!= '') {
      
        callChangePinAPI();
      } else {
        func('Form not valid!');
      }
      setError({...newErrors});
  };

  return (
    <>
    {isLoading && <Loader />}
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="auth-modal modal fade "
      centered
    >
      <Modal.Body>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          aria-label="Close"
        ></button>

        <div className="row justify-content-center">
          <div class="col-lg-9 border-gray-50">
            <form className="row justify-content-center">
              <div
                class="col-12 justify-content-center align-items-center"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h3 class="bodyBoldFont mb-lg-5 mb-3 text-center">
                  {t('right_hand_drop_down.change_pin')}
                </h3>

                <div className="col-sm-6 col-10 mb-4">
                  <label for="inputMobileNumber" className="form-label">
                  {t('right_hand_drop_down.old_pin')}
                  </label>
                  <input
                   type="password"
                   className="form-control"
                   id="inputMobileNumber"
                   placeholder={t('right_hand_drop_down.enter_old_pin')}
                   name="oldpin"
                   onKeyDown={(e) =>
                     ["e", "E", "+", "-"].includes(e.key) &&
                     e.preventDefault()
                   }
                   onChange={(e) =>{
                    let userInput = e.target.value;
                    if (userInput.length <= 6 && !isNaN(userInput)) {
                      setOldPin(userInput);
                    }
                   }}
                   maxLength={6}
                   value={oldPin}
                  //  onBlur={validatePins}
                  />
                  {error.oldPin && <Error error={error.oldPin} />}
                </div>

              <div className="col-sm-6 col-10 mb-4">
                <label for="inputMobileNumber" className="form-label">
                {t('right_hand_drop_down.new_pin')}
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="inputMobileNumber"
                  placeholder={t('right_hand_drop_down.enter_new_pin')}
                  name="pin"
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) &&
                    e.preventDefault()
                  }
                  onChange={(e) => {
                    let userInput = e.target.value;
                    if (userInput.length <= 6 && !isNaN(userInput)) {
                      setNewPin(userInput);
                    }
                  }}
                  maxLength={6}
                  value={newPin}
                  // onBlur={validatePins}
                />
                {/* {console.log(error.newPin)} */}
                {error.newPin && <Error error={error.newPin} />}
              </div>

              <div className="col-sm-6 col-10 mb-4">
                <label for="inputMobileNumber" className="form-label">
                {t('right_hand_drop_down.confirm_pin')}
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="inputMobileNumber"
                  placeholder={t('right_hand_drop_down.enter_confirm_pin')}
                  name="pin"
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) &&
                    e.preventDefault()
                  }
                  onChange={(e) => {
                    let userInput = e.target.value;
                    if (userInput.length <= 6 && !isNaN(userInput)) {
                      setConfirmPin(userInput);
                    }
                  }}
                  maxLength={6}
                  value={confirmPin}
                  // onBlur={validatePins}
                />
                {error.confirmPin && <Error error={error.confirmPin} />}
              </div>             

              <div className="col-sm-6 col-10">
                <button type="submit" className="btn btn-primary btn-arrow"
                  style={{ width: "100%" }} 
                  onClick={(e) => handlerSubmit(e)}
                >
                  {t('right_hand_drop_down.save')}
                </button>
              </div>
            </div>
          </form>

          </div>
        </div>
      </Modal.Body>
    </Modal>
    </>
  );
};

export default ChangePin;
