import React, { createContext, useContext, useEffect, useState } from 'react'
import { d11_color, talabat } from './Utilities/color';
import { func } from './Utilities/logFunc';
const ThemeContext = createContext();

export const useTheme = () => {
  return useContext(ThemeContext);
};
const ThemeProvider = ({children}) => {
    const root = document.documentElement;
  const [theme, setTheme] = useState(talabat);
  const [token, setToken] = useState('');

  const [userData, setUserData] = useState([]);
  Object.entries(theme)?.forEach(([key, value]) => {
    root.style.setProperty(`--${key}`, value);
  });


  const fetchUserData = (data) => {
    setUserData(data?.item)
    setToken(data?.token)
  }
  
  return (
    <ThemeContext.Provider value={{theme, token, userData, fetchUserData}}>
        {children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider