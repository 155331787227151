// import React, { useEffect, useState } from "react";
// import { Modal } from "react-bootstrap";
// import { howToJoinGame } from "../../../Utilities/controller";
// import { func } from "../../../Utilities/logFunc";
// import { toast } from "react-toastify";
// import { logout } from "../../../Utilities/logout";
// import { useTranslation } from "react-i18next";

// const HowToJoin = ({ isHowToJoinOpen, setIsHowToJoinOpen }) => {
//   const [show, setShow] = useState(isHowToJoinOpen);
//   const [contentPairs, setContentPairs] = useState([]);
//   const { t } = useTranslation();

//   const fetchDetails = async () => {
//     const filter = { game: localStorage.getItem("tournamentIdGame") }; // Define the filter object

//     try {
//       const res = await howToJoinGame({ filter }); // Pass the filter object as an argument

//       const dataList = res?.data?.list?.[0]?.content || []; // Access the content array

//       // // Iterate through the content and create pairs of text and image
//       const pairs = [];
//       let textContent = null,
//         imageContest = null;

//       dataList?.forEach((item, index) => {
//         const type = item?.type;
//         const content = item?.content;

//         if (type === "text") {
//           textContent = content;
//         } else if (type === "image") {
//           imageContest = content;
//         }

//         if (textContent && imageContest) {
//           pairs.push({ text: textContent, image: imageContest });
//           textContent = null;
//           imageContest = null;
//         }
//       });

//       // Update state with the pairs
//       setContentPairs(pairs);
//     } catch (error) {
//       // Handle any errors that may occur during the API request
//       if (
//         error?.response?.data?.errors?.[0]?.code ==
//           "middlewares.token_parser.validation.token_invalid" ||
//         error?.response?.data?.errors?.[0]?.code ==
//           "middlewares.token_parser.validation.token_expired"
//       )
//         logout();
//       toast.error(error.response?.data?.errors[0]?.msg);
//     }
//   };

//   const handleClose = () => {
//     setIsHowToJoinOpen((prev) => false);
//     setShow(false);
//   };
//   useEffect(() => {
//     fetchDetails();
//   }, []);
//   return (
//     <Modal
//       show={show}
//       onHide={handleClose}
//       backdrop="static"
//       keyboard={false}
//       className="winningBreakupModal modal fade"
//       centered
//     >
//       {/* <Modal.Header closeButton>
//                      <Modal.Title>Modal title</Modal.Title>
//                    </Modal.Header> */}
//       <Modal.Body>
//         <button
//           type="button"
//           class="btn-close"
//           data-bs-dismiss="modal"
//           aria-label="Close"
//           onClick={handleClose}
//         ></button>
//         <div className="row justify-content-center">
//           <div className="col-xl-6 col-lg-7 col-sm-9 col-12">
//             <div className="tab-content auth-content">
//               <form className="row justify-content-center">
//                 <h2 className="verification_heading text-center">
//                   {t("contest.how_to_join")}
//                 </h2>
//                 <div className="grid-container pt-4">
//                   {contentPairs.map((pair, index) => (
//                     <div
//                       key={index}
//                       // className={`step-number ${
//                       //   index % 4 < 2 ? "right" : "left" // Display first two on right, next two on left
//                       // }`}
//                     >
//                       <div className="step-number mb-4">
//                         {pair?.text.split("\n").map((item) => (
//                           <p className="fs-6">
//                             {/* {pair.text.length > 32 ? (
//                             <marquee style={{ margin: "0", padding: "0" }}>
//                               {pair.text}
//                             </marquee>
//                           ) : (
//                             pair.text
//                           )} */}
//                             {item.replace(/^\s\t$/, "")}
//                           </p>
//                         ))}

//                         <div
//                           style={{
//                             height: "166px",
//                           }}
//                         >
//                           <img
//                             src={pair.image}
//                             className="rounded10 w-100 h-100"
//                             alt=""
//                           />
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export default HowToJoin;


import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { howToJoinGame, getGameList } from "../../../Utilities/controller";
import { func } from "../../../Utilities/logFunc";
import { toast } from "react-toastify";
import { logout } from "../../../Utilities/logout";
import { useTranslation } from "react-i18next";
// import OwlCarousel from "react-owl-carousel";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Cookies from 'js-cookie'
const HowTojoinPopUp = ({ isHowToJoinOpen, setIsHowToJoinOpen }) => {
  const [show, setShow] = useState(isHowToJoinOpen);
  const [contentPairs, setContentPairs] = useState([]);
  const [index, setIndex] = useState("");
  const { t } = useTranslation();
  const [allGames, setAllGames] = useState([]);

  const fetchDetails = async () => {
    const filter = { game: index}; // Define the filter object

    try {
      const res = await howToJoinGame({ filter }); // Pass the filter object as an argument

      const dataList = res?.data?.list?.[0]?.content || []; // Access the content array

      // // Iterate through the content and create pairs of text and image
      const pairs = [];
      let textContent = null,
        imageContest = null;

      dataList?.forEach((item, index) => {
        const type = item?.type;
        const content = item?.content;

        if (type === "text") {
          textContent = content;
        } else if (type === "image") {
          imageContest = content;
        }

        if (textContent || imageContest) {
          pairs.push({ text: textContent, image: imageContest });
          textContent = null;
          imageContest = null;
        }
      });

      // Update state with the pairs
      setContentPairs(pairs);
    } catch (error) {
      // Handle any errors that may occur during the API request
      if (
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_expired"
      )
        logout();
      toast.error(error.response?.data?.errors[0]?.msg);
    }
  };

  const handleClose = () => {
    setIsHowToJoinOpen((prev) => false);
    setShow(false);
  };
  const getAllGameList = async () => {
    try {
      let data = {
        pagination: {
          pageNo: 1,
          recordPerPage: 100,
          sortBy: "order",
          sortDirection: "asc",
        },
      };
      const res = await getGameList(data);
      if (res?.data?.data) {
        let allGames = res?.data?.data;
        setAllGames(allGames);
        setIndex(allGames[0]?.tournamentGameID);
      }
      func("gamelist", res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  useEffect(() => {
    getAllGameList();
  }, []);
  useEffect(() => {
    fetchDetails();
  }, [index]);

  const settingstwo = {
    dots: false,
    infinite: true,
    speed: 500,
    nav: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    // rtl:true,

    responsive: {
      0: {
        items: allGames.length > 3 ? 3 : allGames.length,
        nav: true
      },
      600: {
        items: allGames.length > 3 ? 3 : allGames.length,
        nav: true
      },
      1000: {
        items:allGames.length > 3 ? 3 : allGames.length,
        nav: true,
      },
    },
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="winningBreakupModal modal fade"
      centered
    >
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
       <div class="content text-center">
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="title">
                <h3 class="text-center bodyBoldFont mb-5 pb-1">{t('contest.how_to_join')}</h3>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-8 col-md-10 col-sm-10 col-10 ">
                  <div className="d11-points htjp">
                    <ul className="d-flex justify-content-between">
                    <OwlCarousel
                    rtl = {(localStorage.getItem('tlbLangCode')  == 'ar'  || Cookies.get("tlbLangCode") == 'ar') ? true : false}
                // className="owl-theme"
                // margin={10}
                {...settingstwo}
              >
                      {allGames.map((item, ind) => (
                        <li
                          className={`d11-points-li how-to-join-li ${
                            item?.tournamentGameID == index ? "active-li" : ""
                          }`}
                          // style={{ width: `${100 / allGames.length}%` }}
                          key={item?.tournamentGameID}
                          onClick={() => 
                           {
                            // console.log("cliked")
                            setIndex((prev) => item?.tournamentGameID)
                          }
                          }
                        >
                          {item?.name}
                        </li> 
                                               
                      ))}
                      </OwlCarousel>
                    </ul>
                    <div className="grid-container pt-4" >
                      { contentPairs.length > 0 ?
                       contentPairs.map((pair, index) => (
                          <div key={index}>
                            
                            <div className="step-number mb-4">
                              {pair?.text && pair?.text.split("\n").map((item) => (
                                <p className="fs-6">
                                  {/* {pair.text.length > 32 ? (
                                  <marquee style={{ margin: "0", padding: "0" }}>
                                    {pair.text}
                                  </marquee>
                                ) : (
                                  pair.text
                                )} */}
                                  {item.replace(/^\s\t$/, "")}
                                </p>
                              ))}
                              {
                                pair.image && 
                                <div
                                  style={{
                                    height: "166px",
                                  }}
                                >
                                  <img
                                    src={pair.image}
                                    className="rounded10 w-100 h-100"
                                    alt=""
                                  />
                                </div>
                              }
                            </div>
                          </div>
                        ))
                      : 
                      <h2>{t('notFound.no_records_found')}</h2>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HowTojoinPopUp;
