
export const d11_color = {
    'primary-color': 'rgb(183, 0, 255)',
}
export const gamerji = {
    'primary-color': 'blue'
}
export const talabat = {
    'color-black': '#333',
    'color-white': '#ffffff',

    'font-titles': "gobold_bolditalic",
    'font-body': "codec_proregular",
    'font-body-bold': "codec_probold",
    'font-body-extraBold': "codec_proextrabold",
    'font-body-heavy': "codec_proheavy",

    /* Modal */
    'modal-bg': '#ffffff',

    /* Custom Colors */
    'primary-color': '#FF5A00',
    'primary-color-emphasis': '#FF8C3D',
    'primary-color-dark': '#FF5A00',
    'secondary-color': '#323d48',
    'secondary-color-emphasis': '#F7F7F7',
    //  'secondary-color-emphasis-rgb': 62, 75, 90,
    'accent-color': '#FF5A00',
    'dashboard-bg': '#FF5A00',
    'body-color': '#323d48',
    'dark-color': '#3E4B5A',
    'info-color': '#00c2ff',
    'gray-color': '#a6a6a6',
    'golden-color': '#c5a260',
    'golden-color-emphasis': '#cc9747',
    'navbar-bg-color': '#FFF5F9',
    'light-text': '#707070',
    'light-pink': '#FFE5E7',
    'blue': '#2106BB',

    //   /* Bootstrap Variable Override */
    //  'bs-primary-rgb': var(--primary-color),
    //  'bs-secondary-rgb': var(--secondary-color),
    //  'bs-link-color': var(--primary-color),
    'bs-secondary-bg-subtle': '#323d48',
    //  'bs-success-rgb': 7, 188, 12,
    'disabled': '#3e4b5a79',
    //  'navbar-top-height': 5.25rem
}