import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { func } from "../../../Utilities/logFunc";
import moment from "moment";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie'

import {eventTracking} from "../../../firebaseAnalytics"; 
import {events} from "../../../Utilities/appEvents"; 

const TournamentModal = ({
  showTournamentModal,
  setShowTournamentModal,
  setShowTournamentConfirmationModal,
  setMatch,
  tournaments,
  setShowJVC,
  userInGameName
}) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(showTournamentModal);
  const [error, setError] = useState(t("tournaments.select_match_to_check_next_round_timing"));
  const [finaleDate, setFinaleDate] = useState();
  const [rounds, setRounds] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [timelist, settimeList] = useState([]);
  const [condata, setcondata] = useState([]);

  const handleClose = () => {
    func(setShowTournamentModal);
    setShowTournamentModal((prev) => false);
    setShow(false);
    if (setShowJVC) setShowJVC(false);
  };

  const nextHandler = () => {
    if (error) {
      return;
    }
    eventTracking(events.Next, {
      TLB_GameName: tournaments?.gameAry?.[0]?.name, 
      TLB_GameID: tournaments?.gameAry?.[0]?._id,
      TLB_TournamentID: tournaments?._id, 
      TLB_ContestID: activeId,
      TLB_TournamentDate: tournaments?.startDate,
      TLB_TournamentTime: tournaments?.startDate
    });
    setShowTournamentModal((prev) => false);
    setShowTournamentConfirmationModal((prev) => true);
  };

  const ordinal_suffix_of = (i) => {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  };
  const onSelectTime = (item, e) => {
    eventTracking(events.TOURNAMENT_TIMING_SELECTION, {
      TLB_GameName: tournaments?.gameAry?.[0]?.name, TLB_GameID: tournaments?.gameAry?.[0]?._id,
      TLB_TournamentID: tournaments?._id, TLB_ContestID: item?._id,
      TLB_TournamentDate: tournaments?.startDate, TLB_TournamentTime: tournaments?.startDate
    });
    setError("");
    setMatch(item);
    let temp = [];
    var round = item.code;
    tournaments.rounds?.map((item1, index) => {
      if (index !== 0) {
        item1?.matches?.map((item2, index) => {
          if (item2.contest.findIndex((val) => val === round) !== -1) {
            round = item2.matchId;
            temp.push({
              round: item1.name,
              date: item2.date,
              time: item2.time,
            });
          }
        });
      }
    });
    setRounds((prev) => [...temp]);
  };
  useEffect(() => {
    let timeList=[]
    let cdata=[]
   Promise.all(tournaments?.rounds?.map((item, index) => {
      if(item?.isFinalRound == "no" && index === 0){
        item?.matches?.map((item2, ind) => {
          tournaments?.contestsList?.map((contestData, ind) => {

            if(contestData?.code === item2?.matchId){
              console.log(">>",contestData?.date , contestData.time)
            if(timeList&& timeList.length>0){
             if(!timeList.includes((moment(contestData?.date).format("y-MM-DD") + " " + moment(contestData?.time).format("HH:mm")))){
              cdata.push({code:contestData.code,time:moment(contestData?.date).format("y-MM-DD") + " " + moment(contestData?.time).format("HH:mm")})
              timeList.push(moment(contestData?.date).format("y-MM-DD") + " " + moment(contestData?.time).format("HH:mm"))
             }
            }else{
              cdata.push({code:contestData.code,time:moment(contestData?.date).format("y-MM-DD") + " " + moment(contestData?.time).format("HH:mm")})
              timeList.push(moment(contestData?.date).format("y-MM-DD") + " " + moment(contestData?.time).format("HH:mm"))
             }
            } 
        })
      })
    }
  }))
    settimeList(timeList)
    setcondata(cdata)
    
  }, [tournaments]);

  useEffect(() => {
   console.log(">>>>>>>timelist",timelist,condata)
   }, [timelist]);
   
  const setCommontime = ((tournaments) => {
   return tournaments?.rounds?.map((item, index) => (
      <>
        {item?.isFinalRound == "no" && index === 0 ? (
          <>
            <div class="d11-round-text py-3" key={index} style={{color:"var(--color-white)",fontWeight:"600"}}>
              <h4 class="mb-0">
              {t("tournaments.select_1st_round_time", {
                  n: `${ordinal_suffix_of(index + 1)}`,
                })}
              </h4>
            </div>
        
            <div class="d-flex flex-column text-start mt-4 pt-2">
              {item?.matches?.map((item2, ind) => (
                <>
                  {tournaments?.contestsList?.map(
                    (contestData, ind) => {
                     return  <>
                        {contestData?.code === item2?.matchId && condata.some(item => item.code ===item2?.matchId)&& 
                        (  
                          <div class="form-check" key={ind}>
                          {console.log("<<<<<<<<<condata",condata)}
                            <input
                              class="form-check-input"
                              type="radio"
                              // style={{border: '1px solid #323232'}}
                              name="flexRadioDefault"
                              id={`flexRadioDefault${ind}`}
                              disabled={false}
                              value={contestData?._id}
                              checked={activeId == contestData?._id}
                              onChange={(e) => {
                                func("tou Modal 155", contestData);
                                func("tou Modal 156", e.target.value);
                                setActiveId(contestData?._id);
                                onSelectTime(contestData, e);
                              }}
                              // style={{border:" 1px solid #000"}}
                            />
                            <label
                              class="form-check-label"
                              for={`flexRadioDefault${ind}`}
                            >
                              {moment(item2?.date).format(
                                "DD MMMM Y"
                              )}
                              ,{" "}
                              {moment(item2?.time).format("hh:mm A")}
                            </label>
                          </div>

                        )}
                      </>
                    }
                  )}
                </>
              ))}
            </div>
          </>
        ) : (
          ""
        )}
      </>
        
     
    ))})

  useEffect(() => {
    var finale_index = tournaments?.rounds?.findIndex(
      (val) => val?.isFinalRound?.toLowerCase() === "yes"
    );
    // console.log("finale_index: ", finale_index);
    setFinaleDate(
      finale_index !== -1
        ? tournaments?.rounds?.[finale_index]?.matches?.[0]
        : []
    );
  }, [rounds, error]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="tournamentModal modal fade"
      centered
    >
      <Modal.Body>
        <button
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div class="row justify-content-center">
          <div class="col-lg-11 col-12 px-lg-4">
            <div class="title">
              <h3 class="text-center bodyBoldFont mb-4 pb-3">
                {tournaments?.title}
              </h3>
            </div>
            <div class="text-center">
              <h4 class="mb-3">
                {tournaments?.gameAry?.[0]?.name} -{" "}
                {tournaments?.gameTypeAry?.name}
              </h4>
              <h4 class="text-warning mb-3">
                {moment(tournaments?.startDate).format("DD MMMM Y")} {t("tournaments.to")}{" "}
                {moment(tournaments?.endDate).format("DD MMMM Y")}
              </h4>

             {setCommontime(tournaments)}

              {error ? (
                <>
                  <h4 class="d11-final-text">{error}</h4>
                  <h4 class="bodyBoldFont pt-3 mb-0">
                  {t('tournaments.finale_will_be_scheduled_at',{date:`${moment(finaleDate?.date).format("DD MMMM Y")} ${moment(finaleDate?.time).format("hh:mm A")}`})}
                    {/* Finale will be scheduled at{" "}
                    {moment(finaleDate?.date).format("DD MMMM Y")},{" "}
                    {moment(finaleDate?.time).format("hh:mm A")} */}
                  </h4>
                </>
              ) : tournaments?.rounds?.length > 2 ? (
                <>
                  <OwlCarousel
                    className="owl-carousel owl-theme"
                    autoWidth={false}
                    items={1}
                    loop
                    margin={0}
                    rtl = {(localStorage.getItem('tlbLangCode')  == 'ar'  || Cookies.get("tlbLangCode") == 'ar') ? true : false}
                    // stagePadding={50}
                    autoplay={false}
                    dots={false}
                    nav={true}
                    // navText={[
                    //   <i className="fa fa-arrow-left" aria-hidden="true">hhh</i>,
                    //   <i className="fa fa-arrow-right" aria-hidden="true"></i>,
                    // ]}
                  >
                    {func("time OwlCarousel", rounds)}
                    {rounds &&
                      rounds?.map((item, index) => {
                        return (
                          <div>
                            <h4 class="bodyBoldFont text-uppercase mt-2" style={{color:"var(--color-red)"}}>
                              {item?.round}
                            </h4>
                            <h4 class="bodyBoldFont pt-3 mb-0">
                              {moment(item?.date).format("DD MMMM Y")},{" "}
                              {moment(item?.time).format("hh:mm A")}
                            </h4>
                          </div>
                        );
                      })}
                  </OwlCarousel>
                </>
              ) : (
                <>
                  <h4 class="bodyBoldFont text-uppercase mt-2">{t("tournaments.final")}</h4>
                  <h4 class="bodyBoldFont pt-3 mb-0">
                    {moment(finaleDate?.date).format("DD MMMM Y")},{" "}
                    {moment(finaleDate?.time).format("hh:mm A")}
                  </h4>
                </>
              )}

              {
                // item?.isFinalRound == "yes" ?
                // (
                //   <>
                //     {item?.matches?.map((item2) => (
                //       <>
                //         <h4 class="d11-final-text">
                //         {
                //           error && error
                //         }
                //         </h4>
                //         <h4 class="bodyBoldFont pt-3 mb-0">
                //         Finale will be scheduled at  {moment(item2?.date).format("DD MMMM Y")},{" "} {moment(item2?.time).format("hh:mm A")}
                //         </h4>
                //       </>
                //     ))}
                //   </>
                // ) : ""}
              }

              <div class="row justify-content-center mt-4 pt-2">
                <div class="col-lg-7">
                  <div class="d-grid">
                    <button
                      onClick={nextHandler}
                      class="btn btn-primary btn-arrow"
                    >
                      {t("tournaments.next")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TournamentModal;
