import React from 'react'
import NotFound from '../assets/images/notFound404.png'
const PageNotFoundError = () => {
  return (
    <main className='not-found dashboardMain'>
        <div className=' mt-5'>
            <div>
        <img  src={NotFound}/>
        </div>
        <div className='text-center'>
        <h4 >Sorry!</h4>
        <h6 >Could not find the page which you are looking for ☹️</h6>
        </div>
        </div>
    </main>
  )
}

export default PageNotFoundError