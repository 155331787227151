import React, { useState } from "react";
import LandingPage from "./LandingPage";
import IPAD from "./images/macbook.png";
import game1 from "./images/PUBG_T.png";
import game2 from "./images/VALO_T.png";
import game3 from "./images/COD_T.png";
import game5 from "./images/RL_T.png";
import img4922 from "./images/50voucher.png";
import partner from "./images/100voucher.png";
import heroImage from "./images/headerbg.png";
// import OwlCarousel from "react-owl-carousel";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate } from "react-router";
import { routes } from "../../Utilities/routesFb";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

const LandingMain = () => {
  const [active, setActive] = useState(5);
  const {t} = useTranslation()
  const navigate = useNavigate();
  const translatedText = t("landing_page.games_text", {
    interpolation: { escapeValue: false },
  });
  const settings = {
    stagePadding: 30,
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 300,
    loop: true,
    nav: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 5,
      },
      1000: {
        items: 4,
      },
    },
  };

  const settingstwo = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      600: {
        items: 1,
        nav: true,
      },
      1000: {
        items: 2,
        nav: true,
        
      },
    },
  };

  return (
    <LandingPage active={active} setActive={setActive}>
      <main class="full-page-sections">
        <section class="hero_section">
          <div class="hero_section--card w-100">
            <div class="container">
              <div class="row d-flex align-items-center hero-area">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div class="banner-content">
                    <h2>{t('landing_page.discover')}</h2>
                    {/* <p>
                      Ready to take your gaming to the next level? BISB is the
                      ultimate destination for competitive esports gaming in the
                      Middle East. Come join us for daily tournaments, meet
                      other gamers, and show off your skills for a chance to win
                      prizes and get rewarded! So, what are you waiting for?
                      Let’s get in the game and level up!
                    </p> */}
                    <Link to={`${process.env.REACT_APP_CLIENT_BASE_URL}home`} class="btn btn-know">
                    {t('landing_page.get_started')}
                    </Link>{" "}
                  </div>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div class="hero_section-cardImage">
                    {/* <img
                      src={IPAD}
                      class="img-fluid hero_section--image rounded-start"
                      alt="..."
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="gameSec">
          <div class="container-fluid">
            <div class="card">
              <div class="row d-flex align-items-center">
                <div class="col-md-6 col-12">
                  <div class="card-body p-2">
                    <h1 class="mb-3 game_section--title mb-5">{t('landing_page.games')}</h1>
                    <h2 class="game_section--heading">
                    {t('landing_page.games_title')}
                    </h2>
                    <p class="game_section--text">
                    <span style={{color:'#aaa'}}
                              dangerouslySetInnerHTML={{
                                __html: translatedText,
                              }}
                            />
                    {/* {t('landing_page.games_text')} */}
                    </p>
                    <a href={`${process.env.REACT_APP_CLIENT_BASE_URL}home`} class="btn btn-know margin-right-sm">
                    {t('landing_page.know_more')}
                    </a>
                  </div>
                </div>
                <div class="col-md-6 col-12 ">
                  <div class="games-section">
                    <div class="owl-slider gameCarousel">
                      <OwlCarousel
                        className="owl-theme"
                        loop
                        margin={10}
                        rtl = {localStorage.getItem('tlbLangCode') == 'ar' || Cookies.get("tlbLangCode") == 'ar' ? true : false}
                        {...settings}
                        nav
                      >
                        <div class="item">
                          <img src={game1} alt="" />
                        </div>
                        <div class="item">
                          <img src={game2} alt="" />
                        </div>
                        <div class="item">
                          <img src={game3} alt="" />
                        </div>
                        <div class="item">
                          <img src={game5} alt="" />
                        </div>
                        {/* <div class="item">
                          <img src={game3} alt="" />
                        </div> */}
                        {/* <div class="item">
                          <img src={game2} alt="" />
                        </div>
                        <div class="item">
                          <img src={game3} alt="" />
                        </div>
                        <div class="item">
                          <img src={game5} alt="" />
                        </div>
                        <div class="item">
                          <img src={game1} alt="" />
                        </div>
                        <div class="item">
                          <img src={game2} alt="" />
                        </div> */}
                      </OwlCarousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="prizeSec py-4">
          <div class="container">
            <div class="prize_section--textarea">
              <h1 class=" prize_section--title ">{t('landing_page.prize')}</h1>
              <p class="prize_section--text mb-4" style={{color:'#AAA'}}>
              {t('landing_page.play_favourite')}
              </p>
            </div>
            <div class="home-demo prizeCarousel">
              <OwlCarousel
                className="owl-theme"
                rtl = {(localStorage.getItem('tlbLangCode')  == 'ar'  || Cookies.get("tlbLangCode") == 'ar') ? true : false}
                
                margin={20}
                {...settingstwo}
                nav
              >
                
                <div class="bisbcard item ">
                  <div class="card--text-area ">
                    <h2>{t('landing_page.card1_title')}</h2>
                    <p>{t('landing_page.benefits')}</p>
                    <div class="partnercard">
                      <ul>
                      <li>{t('landing_page.card1_l1')}</li>
                        <li>{t('landing_page.card1_l2')}</li>
                        <li>{t('landing_page.card1_l3')}</li>
                      </ul>
                     
                    </div>
                    <button class="btn btn-know btn-blue" onClick={()=>
               navigate("/home/"+routes.rewardStore, {state: {redirectReward:'redirectReward'}})
                        // window.location.href = `${process.env.REACT_APP_CLIENT_BASE_URL}home/reward-store`
                      }>{t('landing_page.know_more')}</button>
                  </div>
                  <div class="card--img" >
                    <img src={partner} class="cardImagetwo" alt="" />
                  </div>
                </div>
                <div class="bisbcard item ">
                  <div class="card--text-area ">
                    <h2>{t('landing_page.card2_title')}</h2>
                    <p>{t('landing_page.benefits')}</p>
                    <div class="partnercard">
                      <ul>
                      <li>{t('landing_page.card2_l1')}</li>
                        <li>{t('landing_page.card2_l2')}</li>
                        <li>{t('landing_page.card2_l3')}</li>
                      </ul>
                     
                    </div>
                    <button class="btn btn-know btn-blue" onClick={()=>
               navigate("/home/"+routes.rewardStore, {state: {redirectReward:'redirectReward'}})
                        // window.location.href = `${process.env.REACT_APP_CLIENT_BASE_URL}home/reward-store`
                      }>{t('landing_page.know_more')}</button>
                  </div>
                  <div class="card--img" >
                    <img src={img4922} class="cardImagetwo" alt="" />
                  </div>
                </div>
              
               
              </OwlCarousel>
            </div>
            <div class="text-center my-4">
              <button class="btn btn-know viewall" onClick={()=>
               navigate("/home/"+routes.rewardStore, {state: {redirectReward:'redirectReward'}})
                        // window.location.href = `${process.env.REACT_APP_CLIENT_BASE_URL}home/reward-store`
                      }>{t('landing_page.view_all')}</button>
            </div>
          </div>
        </section>
      </main>
    </LandingPage>
  );
};

export default LandingMain;
