import React, { useEffect, useState } from "react";
import FeaturedVideos from "./FeaturedVideos";
import PopulatVideos from "./PopulatVideos";
import TopProfiles from "./TopProfiles";
import News from "./News";
import {eventTracking} from "../../../firebaseAnalytics"; 
import {events} from "../../../Utilities/appEvents"; 

const WorldOfEsports = ({ handleClickBlog }) => {
  useEffect(()=>{
    EventTracking(); 
  },[]);
  const EventTracking=()=>{
    eventTracking(events.MENU.WOE);
  }

  return (
    <div class="row gy-5">
      <FeaturedVideos />
      <PopulatVideos />
      {/* <TopProfiles /> */}
      <News handleClickBlog={handleClickBlog} />
    </div>
  );
};

export default WorldOfEsports;
