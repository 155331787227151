export const events = {
  CHANGE_LANGUAGE: "change_language",
  TERM_N_CONDITION: "terms_conditions_signup",
  PRIVACY_POLICY: "privacy_policy_signup",
  register: "register",
  APPLY_SIGNUP_CODE: "apply_signup",
  REGISTER_TAB: "register_tab",
  SIGNUP_COMPLATE: "submit_singup_complete",
  SIGNUP_RESEND_OTP: "resend_signup",
  SIGNUP_OTP_VERIFIED: "otp_verified_signup",
  LOGIN: {
    LOGIN: "login_with_phone",
    RESET_PIN: "reset_pin",
  },
  LOGIN_TAB: "login_tab",
  REQ_FORGOT_PIN_OTP: "request_forgot_pin_otp",
  VERIFY_FORGOT_PIN_OTP: "verify_forgot_pin_otp",
  LOGOUT: "logout",
  JOIN_VIA_INVITE_CODE: "join_via_invite",
  my_profile: "my_profile",
  how_to_join_header: "how_to_join_header",
  legality: "legality",
  CHANGE_PIN: "change_pin",
  FAV_GAMES: "favourite_game",
  gamerji_point: "talabat_point",
  MENU: {
    All_GAMES: "all_games_menu",
    WOE: "world_of_esprots_menu",
    CRM: "crm_menu",
    LEADERBOARD: "leaderboard_menu",
    REWARD_STORE: "reward_store_menu",
  },
  FEATURE_TOURNAMENTS: "featured_tournaments",
  BANNER: "banners",
  Tournamnet_Winners: "tournamnet_winners",
  UPDATE_PIN: "update_pin",
  gamenamebox: "gamenamebox",
  change_username: "change_username",
  change_username_AddUpdate: "change_username_update",
  Ticket_box: "ticket_box",
  Raise_a_Complaint: "raise_a_complaint",
  Chat: "chat",
  FAV_GAMES_SUBMIT: "favourite_game_submit",
  JOIN_VIA_INVITE_CODE_SUBMIT: "join_via_invite_submit",
  Search: "search",
  Today_Tab: "today_tab",
  Monthly_Tab: "monthly_tab",
  All_Tab: "all_tab",
  Weekly_Tab: "weekly_tab",
  Terms_of_use_Tab: "terms_of_use_tab",
  Privacy_Policy_Tab: "privacy_policy_tab",
  Reward_Tab: "reward_tab",
  My_Reward_Tab: "my_reward_tab",
  Redeem_Rewards: "redeem_rewards",
  copy_redeem: "copy_redeem",
  PROFILE_EDIT: "profile_edit",
  PROFILE_SHARE: "profile_share",
  PROFILE_EDIT_SUBMIT: "profile_edit_submit",
  STATISTIC_TAB: "statistic_tab",
  View_Insights: "view_insights",
  VIEW_ALL_LEVELS: "view_all_levels",
  mycontests_contests: "mycontests_contests",
  mycontests_tournaments: "mycontests_tournaments",
  game_sub_types: "game_sub_types",
  tournaments: "tournaments",
  Tournament_JoinNow: "tournament_join_now",
  Tournamnet_Winners: "tournamnet_winners",
  Tournament_Rules: "tournament_rules",
  JOIN_TOURNAMENT: "join_tournament",
  Tournament_Contest_winners: "tournaments_rounds_winners",
  Tournament_Room_ID: "tournament_room_id",
  Tournament_Passsword: "tournament_passsword",
  Tournament_Share_With_Friends: "tournament_share_with_friends",
  Next: "next",
  TOURNAMENT_TIMING_SELECTION: "tournament_timing_selection",
  CONTEST_LIST: {
    JOIN_NOW: "contests_join_now",
    JOIN_CONTEST: "contests_joined",
  },
  Contest_winners: "contest_winners",
  Contest_Room_ID: "contest_room_id",
  Contest_Passsword: "contest_passsword",
  Share_With_Friends: "share_with_friends",
  how_to_join: "how_to_join",
  my_contests: "my_contests",
};
