import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import Today from "./Today";
import ByWeek from "./ByWeek";
import ByMonth from "./ByMonth";
import AllOver from "./AllOver";
import { useTranslation } from "react-i18next";
import {eventTracking} from "../../../firebaseAnalytics"; 
import {events} from "../../../Utilities/appEvents"; 
const LeaderBoardInfo = () => {
  let [leaderboardType, setLeaderboardType] = useState("today");
  const {t} = useTranslation()

  useEffect(()=>{
    EventTracking(); 
  },[]);
  const EventTracking=()=>{
    eventTracking(events.MENU.LEADERBOARD);
    eventTracking(events.Today_Tab);
  }

  const loadMoreBtnClick = (type) => {
    setLeaderboardType(type);
  };

  return (
    <div class="col-12 mt-5 pt-lg-4 commonTab leaderBoardTab">
      <ul class="nav nav-tabs row" id="leaderBoardTab" role="tablist">
        <li class="nav-item col d-grid" role="presentation">
          <button
            class="nav-link active fs-dark2"
            id="today-tab"
            data-bs-toggle="tab"
            data-bs-target="#today-tab-pane"
            type="button"
            role="tab"
            aria-controls="today-tab-pane"
            aria-selected="true"
            onClick={() => {
              eventTracking(events.Today_Tab);
              loadMoreBtnClick("today");
            }}
            
          >
            {t('leaderboard.today')}
          </button>
        </li>
        <li class="nav-item col d-grid" role="presentation">
          <button
            class="nav-link fs-dark2"
            id="byWeek-tab"
            data-bs-toggle="tab"
            data-bs-target="#byWeek-tab-pane"
            type="button"
            role="tab"
            aria-controls="byWeek-tab-pane"
            aria-selected="false"
            onClick={() => {
              loadMoreBtnClick("byWeek")
              eventTracking(events.Weekly_Tab);
             }}
          >
            {t('leaderboard.by_week')}
          </button>
        </li>
        <li class="nav-item col d-grid" role="presentation">
          <button
            class="nav-link fs-dark2"
            id="monthly-tab"
            data-bs-toggle="tab"
            data-bs-target="#monthly-tab-pane"
            type="button"
            role="tab"
            aria-controls="monthly-tab-pane"
            aria-selected="false"
            onClick={() => {
              loadMoreBtnClick("byMonthly") 
              eventTracking(events.Monthly_Tab);
             }}
          >
            {t('leaderboard.monthly')}
          </button>
        </li>
        <li class="nav-item col d-grid" role="presentation">
          <button
            class="nav-link fs-dark2"
            id="allTime-tab"
            data-bs-toggle="tab"
            data-bs-target="#allTime-tab-pane"
            type="button"
            role="tab"
            aria-controls="allTime-tab-pane"
            aria-selected="false"
            onClick={() => {
              loadMoreBtnClick("all")
              eventTracking(events.All_Tab);
             }}
          >
            {t('leaderboard.all_time')}
          </button>
        </li>
      </ul>
      <div class="tab-content" id="leaderBoardTabContent">
        <Today leaderboardType={leaderboardType} />
        <ByWeek leaderboardType={leaderboardType} />
        <ByMonth leaderboardType={leaderboardType} />
        <AllOver leaderboardType={leaderboardType} />
      </div>
    </div>
  );
};

export default LeaderBoardInfo;
