import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import JoinModal from "./Tournament/JoinModal";
import WinningBreakUp from "./Tournament/WinningBreakUp";
import TournamentModal from "./Tournament/TournamentModal";
import TournamentConfirmationModal from "./Tournament/TournamentConfirmationModal";
import RulesModal from "./Tournament/RulesModal";
import { func } from "../../Utilities/logFunc";
import {
  getTournament,
  walletUsageLimit,
  getUserInGameName,
  getAccountProfile,
  getTournamentViaCode,
  getFeaturedTournaments,
} from "../../Utilities/controller";
import { url } from "../../Utilities/url";
import Trophy from "../../assets/images/trophy-svg.svg";
import BasicProfile from "./Tournament/BasicProfile";
import Loader from "../Common/Loader";
import { toast } from "react-toastify";
import ShowFreeContestModal from "../Subscription/ShowFreeContestModal";
import { useProfileDataContext } from "./ProfileContext";
import { useTranslation } from "react-i18next";
import {eventTracking} from "../../firebaseAnalytics"; 
import {events} from "../../Utilities/appEvents"; 
const FeaturedTournament = () => {
  var user_data = JSON.parse(localStorage.getItem("profile"));
  const [tournaments, setTournaments] = useState([]);
  const [showJoinModal, setShowJoinModal] = useState(false);
  const [showWinningModal, setShowWinningModal] = useState(false);
  const [showTournamentModal, setShowTournamentModal] = useState(false);
  const [showTournamentConfirmationModal, setShowTournamentConfirmationModal] =
    useState(false);
  const [isLoading, setLoading] = useState(false);

  const [selectedMatch, setMatch] = useState(null);
  const [selected, setselected] = useState([]);
  const [walletData, setWalletData] = useState([]);
  const [activeTournament, setActiveTournament] = useState(null);
  const [activeCurrencyWallet, setActiveCurrencyWallet] = useState(null);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [activeInGameName, setActiveInGameName] = useState(null);
  const [inGameNames, setInGameNames] = useState([]);
  const [showPremiumPopupContest, setShowPremiumPopupContest] = useState(false);
  const [showPremiumPopupTournament, setShowPremiumPopupTournament] = useState(false);

  const [showRulesModal, setShowRulesModal] = useState(false);

  const [showBasicProfileModal, setShowBasicProfileModal] = useState(false);
  const [userDataInfo, setUserData] = useState("");
  const [loader, setLoader] = useState(false);
  const { profileData, updateProfileData } = useProfileDataContext()
  const {t} = useTranslation()
  const getTournamentList = async () => {
    setLoader(true);
    try {
      let data = {
        skip: 0,
        limit: 100,
        filter: {
          isFeatured: true,
          user: profileData?.item.user_id,
          tournamentType: "tlb",
        },
      };

      const res = await getFeaturedTournaments(data);
      if (res?.data) {
        setTournaments(res?.data?.data);
       

        setLoader(false);
      }
      func(res);
    } catch (error) {
      setLoader(false);
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      toast.error(error?.response?.data?.errors?.[0]?.msg)
     
    }
  };

  useEffect(() => {
    // getAccountProfileDetails();
    // getUserInGameNameData();
  }, []);


  const getUserInGameNameData = async () => {
    setLoader(true);
    try {
      let data = {
        search: {
          // phone: userDataInfo?.item?.phone,
          // phoneCode: userDataInfo?.item?.phoneCode,
          game: localStorage.getItem("activeGameId"),
          "user": profileData?.item?.user_id,

        },
      };
      func("localStorage.getItem()", localStorage.getItem("activeGameId"))
      const res = await getUserInGameName(data);
      if (res?.data?.data && res?.data?.data?.length > 0) {
        
        setInGameNames(res?.data?.data)
        // console.log(res?.data?.data)
        setActiveInGameName(res?.data?.data?.[0]?.userInGameName)
        localStorage.setItem("activeGameName",res?.data?.data?.[0]?.userInGameName)
        // let index = res?.data?.data.findIndex(obj => obj.game == gameType?.game?._id)
        // console.log("index", index);

        // if (index > -1) {
        // setUserInGameName(res?.data?.data[index].userINGameName || "")
        // }
        setLoader(false);
        return true;
      } else {
        setLoader(false);
        return false;
      }
    } catch (error) {
      setLoader(false);
      setShowJoinModal(false)
      setShowBasicProfileModal(true)
    }
  };

  const handlerJoin = async (data) => {
    getWallet(data);
    setActiveTournament(data);
    setSelectedTournament(data);
    let checkInNameGame = await getUserInGameNameData();
    if (checkInNameGame) {
      setShowTournamentModal(true);
    } else {
      setShowBasicProfileModal(true);
    }
  };

  const getWallet = async (dataItem) => {
    setLoader(true);
    try {
      let data = {
        event: dataItem?._id,
        type: "event",
        currencyCode: dataItem?.currency?.[0]?.inCurrency?._id,
        entryFee: dataItem?.entryFee,
      };

      const res = await walletUsageLimit(data);
      if (res?.data?.item) {
        setActiveCurrencyWallet(res?.data?.item);
        setLoader(false);
      }
    } catch (error) {
      func("getTournament error", error?.response?.data?.errors?.[0]?.msg);
      toast.error(error?.response?.data?.errors?.[0]?.msg)
      setLoading(false);
    }
  };

  useEffect(() => {
    if(localStorage.getItem('token'))
    getTournamentList();
  }, []);


  const handlerJoinPopup = async (data) => {
  // func("clikced", data);
  // console.log( data, tournaments)
  setLoading(true)
    localStorage.setItem("activeGameId", data?.gameAry?._id);
    localStorage.setItem("activeGame", data?.gameAry?.name);
try{

    let payload = {
      code: data.shortCode,
      user: profileData?.item?.user_id

    }
    const res = await getTournamentViaCode(payload);
    eventTracking(events.FEATURE_TOURNAMENTS,{
      TLB_Game_Name:data?.gameAry?.name,
      TLB_Game_Id:data?.gameAry?._id,
      TLB_Tournament_ID:res?.data?.item._id
    });
    if (res?.data?.success == true) {
      func("res?.data getTournamentViaCode", res?.data.item)
      // console.log(res?.data?.item?.userInGameName,inGameNames)
      // let index = inGameNames?.findIndex(obj => obj.game == data?.gameAry?.[0]?._id)
      // console.log(inGameNames)
      // if (index > -1) {
      //   setActiveInGameName(res?.data?.item?.userInGameName)
      // }
      // if (res?.data?.item?.userInGameName) 
      setActiveInGameName(res?.data?.item?.userInGameName)
    // else{
    //   if (index > -1) {
    //     setActiveInGameName(res?.data?.item?.userInGameName)
      // }
    // }
      setLoading(false)
      setShowJoinModal(true);
      setActiveTournament(res?.data?.item);
      setSelectedTournament(res?.data?.item);
    }
  }
    catch(error){
      setLoading(false)
      toast.error(error?.response?.data?.errors?.[0]?.msg)
    }
      // setActiveTournament(res?.data?.itme);
      // setShowJoinModal(true);
    }
  

  const handlerJoinNow = async (tournaments) => {
    // getWallet(tournaments);
    setActiveTournament(tournaments);
    setSelectedTournament(tournaments);
    // console.log(tournaments?.userInGameName)
    let checkInNameGame = true
    if (!tournaments?.userInGameName){
      checkInNameGame = await getUserInGameNameData();
     
    }
    // console.log(checkInNameGame)
    if(checkInNameGame){
      setShowJoinModal(false);
      setShowTournamentModal(true);
    }
    
   
  };

  const handlerWinning = () => {
    func("clicked");
    eventTracking(events.Tournamnet_Winners, {
      TLB_GameName: localStorage.getItem("activeGame"),
      TLB_GameID: localStorage.getItem("activeGameId"),
      TLB_ContestID: activeTournament._id,
      TLB_GameTypeID:activeTournament.gameTypeAry._id,
    });
    setShowWinningModal((prev) => true);
  };

  return loader ? (
    <Loader />
  ) : (
    <>
      {tournaments?.length > 0 && (
        <div class="col-12">
          <div class="title mb-3">
            <h2 class="h3 d-flex align-items-center">
              <span class="titleIcon me-3">
                <img src={Trophy} alt="" />
              </span>
              <span class="text-warning">  {t('home.featured_tournament')}  </span>
            </h2>
          </div>

          <Swiper
            className="swiper featuredTournamentSlider commonSlider"
            spaceBetween={20}
            slidesPerView={2}
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
            navigation
            pagination={{ clickable: true, el: ".swiper-pagination" }}
            modules={[Navigation, Pagination]}
            style={{ cursor: "pointer" }}
            breakpoints={{
              768: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 4,
              },
            }}
          >
            {tournaments.length > 0 &&
              tournaments.map((data, indp) => (

                <SwiperSlide
                  class="swiper-slide"
                  style={{height:'20rem'}}
                  onClick={() => handlerJoinPopup(data)}
                >
                  <div className="d11-featured-bg">
                    <div class="featuredGameCard card" 
                    style={{ marginTop: '-3rem', borderTopRightRadius: '10px' }}
                    >
                      <div class="card-img" style={{ borderTopRightRadius: '10px' }}>
                        <img
                          class="rounded10"
                          src={url.imageUrl + data?.featuredImage?.default}
                          alt={data?.title}
                        />
                      </div>
                      <div class="card-content">
                        {/* {console.log(data?.length)} */}
                        <h2 class="h6 ">
                          {/* {data?.title} */}
                          {
                            data?.title?.length > 32? <marquee>{data?.title}</marquee>
                            : data?.title
                          }
                          
                          </h2>
                        <hr />
                        <p class="h6 mb-0">{data?.gameAry?.name} - {data?.gameTypeAry?.name}</p>
                      </div>
                    </div>
                    <div class="gameCost">
                      {
                        !data?.rewardDisplayText &&  <img
                        src={
                          url.imageUrl +
                          data?.currency?.outCurrency?.img?.default
                        }
                        alt={data?.title}
                      />
                      }
                     
                      <h4 class="mb-0 bodyBoldFont text-light">{data?.rewardDisplayText ? data?.rewardDisplayText : data?.prizePool }</h4>
                    </div>
                  </div>
                </SwiperSlide>

              ))}
            <div class="swiper-pagination"></div>
          </Swiper>
        </div>
      )}
      {showJoinModal && (
        <JoinModal
          showJoinModal={showJoinModal}
          setShowJoinModal={setShowJoinModal}
          handlerWinning={handlerWinning}
          handlerJoinNow={handlerJoinNow}
          tournaments={activeTournament}
          setShowRulesModal={setShowRulesModal}
          setSelectedTournament={setSelectedTournament}
        />
      )}
      {showWinningModal && (
        <WinningBreakUp
          showWinningModal={showWinningModal}
          setShowWinningModal={setShowWinningModal}
          selectedTournament={selectedTournament}
        />
      )}
      {showTournamentModal && (
        <TournamentModal
          showTournamentModal={showTournamentModal}
          setShowTournamentModal={setShowTournamentModal}
          setShowTournamentConfirmationModal={
            setShowTournamentConfirmationModal
          }
          setMatch={setMatch}
          tournaments={activeTournament}
        />
      )}
{/* {console.log(activeInGameName)} */}
      {showTournamentConfirmationModal && (
        <TournamentConfirmationModal
          showTournamentConfirmationModal={showTournamentConfirmationModal}
          setShowTournamentConfirmationModal={
            setShowTournamentConfirmationModal
          }
          tournaments={selectedTournament}
          walletData={activeCurrencyWallet}
          match={selectedMatch}
            userInGameName={activeInGameName || localStorage.getItem('activeInNameGame')}
            setShowPremiumPopupTournament={setShowPremiumPopupTournament}
        />
      )}

      {showRulesModal && (
        <RulesModal
          showRulesModal={showRulesModal}
          setShowRulesModal={setShowRulesModal}
          selectedTournament={selectedTournament}
        />
      )}

      {showBasicProfileModal && (
        <BasicProfile
          showBasicProfileModal={showBasicProfileModal}
          setShowBasicProfileModal={setShowBasicProfileModal}
          game={localStorage.getItem("activeGameId")}
          setShowTournamentModal={setShowJoinModal}
        />
      )}
       {
        showPremiumPopupContest  && <ShowFreeContestModal title={"contest"} showPremiumPopupContest ={showPremiumPopupContest} setShowPremiumPopupContest={setShowPremiumPopupContest}  />
      }
       {
        showPremiumPopupTournament  && <ShowFreeContestModal title={"tournament"} showPremiumPopupContest ={showPremiumPopupTournament} setShowPremiumPopupContest={setShowPremiumPopupTournament}  tournaments={selectedTournament} />
      }
    </>
  );
};

export default FeaturedTournament;
