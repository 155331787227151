import React, { useEffect, useState } from "react";

import { func } from "../../../Utilities/logFunc";
import { liveStreamingsList } from "../../../Utilities/controller";
import { url } from "../../../Utilities/url";
import { toast } from "react-toastify";
import { logout } from "../../../Utilities/logout";
import logo from '../../../assets/images/logo.png';
import { useTranslation } from "react-i18next";
const FeaturedVideos = () => {
  let [videoData, setVideoData] = useState([]);
  const {t} = useTranslation()

  const fetchLiveStreamingsList = async (totalCount) => {
    try {
      let data = {
        skip: 0,
        limit: 1,
        sortBy: "order",
        sort: "asc",
        filter: {
          isLive: false,
        },
      };

      const res = await liveStreamingsList(data);
      if (res?.data?.list) {
        setVideoData(res?.data?.list);
      }
      func(res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      toast.error(error?.response?.data?.errors[0]?.msg);
      if (error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired" ||  error?.response?.data?.errors?.[0]?.code == 'auth.validation.signin.account_inactive'){
        console.log("Featuredd")
      logout()

      }
      

    }
  };

  useEffect(() => {
    fetchLiveStreamingsList();
  }, []);

  const videoUrls = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url?.match(regExp);

    let videoId = match && match[2]?.length === 11 ? match[2] : null;

    return "//www.youtube.com/embed/" + videoId;
  };

  return (
    <>
     
      {videoData?.length > 0 && (
        <div class="col-12">
         <div class="mb-3 d-flex align-items-center justify-content-between">
            <h2 class="h3 bodyBoldFont mb-0">{t('woe.featured_videos')}</h2>
           
          </div>
          <div class="card woeFeaturedVideoCard " >
            
            <div class="card-body " style={{padding:'1.5rem'}}>
              <div class="card-video" style={{height:'31.25rem'}}>
                <iframe
                  class="rounded10"
                  width="100%"
                  style={{height:'31.25rem'}}
                  src={videoUrls(videoData[0]?.url)}
                  title={videoData[0]?.name}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen={true}
                ></iframe>
              </div>
              <div class="card-info d-flex align-items-center mt-2 pt-1 px-0">
                <div class="d-flex align-items-center">
                  <img
                    class="img-thumbnail-65"
                    // style={{width: '10%'}}
                    src={ videoData[0]?.logo?.default ? url.imageUrl + videoData[0]?.logo?.default : logo}
                    alt=""
                  />
                  <div class="info ms-2 ps-1">
                    <h4 class="mb-1 bodyBoldFont">
                   { videoData[0]?.name}
                    </h4>
                    {/* <h5 class="mb-0 bodyBoldFont">D11 Gaming</h5> */}
                  </div>
                </div>
                {/* <div class="ms-auto">
                  <div class="dropdown customDropdown">
                    <a
                      class="dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="/assets/images/verticalDot-icon-svg.svg"
                        alt=""
                      />
                    </a>

                    <ul class="dropdown-menu">
                      <li>
                        <a class="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FeaturedVideos;
